import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useChangeUser, useCreateHeadsetAsUser } from 'api';
import { Modal, Button, Tabs } from 'components';
import { User } from 'types';
import style from './change-user-modal.module.scss';
import { Status, DATA_TABS_STATUS, CONVERT_STATUS_TO_BOOLEAN } from './constants';

type ChangeUserModalProps = {
  userData: User;
  onClose: () => void;
};

export const ChangeUserModal: FC<ChangeUserModalProps> = ({ userData, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.change-user-modal' });
  const { trigger: changeUser } = useChangeUser();
  const { trigger: createHeadsetAsUser } = useCreateHeadsetAsUser();
  const { id, username, is_active, headset } = userData;
  const [status, setStatus] = useState<string>(is_active ? Status.Active : Status.NotActive);
  const isActive = CONVERT_STATUS_TO_BOOLEAN[status];

  const handleSave = async () => {
    try {
      const body = { is_active: isActive };
      await changeUser({ id, body });

      onClose();
      toast.success(t('userSuccessMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handleCreateHeadsetAsUser = async () => {
    try {
      await createHeadsetAsUser({ as_user_id: id });

      toast.success(t('headsetSuccessMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const isDisabled = isActive === is_active;

  return (
    <Modal title={t('title', { username })} onClose={onClose}>
      <div className={style.content}>
        <div className={style.item}>
          {headset ? (
            <div className={style.userHeadset}>{t('hasHeadset')}</div>
          ) : (
            <Button
              classNames={{ button: style.headsetButton }}
              label={t('createHeadset')}
              onClick={handleCreateHeadsetAsUser}
            />
          )}
        </div>
        <Tabs
          classNames={{ block: style.item }}
          label={t('statusesLabel')}
          data={DATA_TABS_STATUS.map(item => ({ ...item, label: t(item.label) }))}
          activeId={status}
          onChange={setStatus}
        />
        <div className={style.buttons}>
          <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
          <Button
            classNames={{ button: style.button }}
            isDisabled={isDisabled}
            label={t('save')}
            onClick={handleSave}
          />
        </div>
      </div>
    </Modal>
  );
};
