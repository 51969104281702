import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SlOptionsVertical } from 'react-icons/sl';
import { Socket } from 'socket.io-client';
import { InputRange } from 'components';
import { RANGES_DATA } from './constants';
import style from './controls.module.scss';

type OptionsModalProps = {
  socket: Socket;
  options: Record<string, number>;
  setOptions: React.Dispatch<React.SetStateAction<Record<string, number>>>;
};

const OptionsModal: FC<OptionsModalProps> = ({ socket, options, setOptions }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const display = useMemo(
    () =>
      RANGES_DATA.map(item => {
        const { id, min, max, step, labelValue } = item;
        const itemValue = options[id];

        const handleChange = (value: number) => {
          setOptions(state => ({ ...state, [id]: value }));
          socket.emit(id, itemValue.toString());
        };

        return (
          <InputRange
            key={id}
            classNames={{ block: style.item }}
            id={id}
            label={t(`controls.options.${id}`)}
            min={min}
            max={max}
            step={step}
            value={itemValue}
            labelValue={labelValue}
            onChange={handleChange}
          />
        );
      }),
    [t, options, socket, setOptions],
  );

  return <div className={style.optionsModal}>{display}</div>;
};

type OptionsProps = {
  socket: Socket;
};

export const Options: FC<OptionsProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<Record<string, number>>({
    mouse_sensitivity: 1,
    keyboard_sensitivity: 1,
    depth_view: 1,
  });

  const handleToggleOpen = () => {
    setIsOpen(state => !state);
  };

  return (
    <div className={style.optionsContainer}>
      <button className={style.button} title={t('controls.optionstTitle')} onClick={handleToggleOpen}>
        <SlOptionsVertical size='20px' color='black' />
      </button>
      {isOpen && <OptionsModal socket={socket} options={options} setOptions={setOptions} />}
    </div>
  );
};
