export const FINISH_MEETING_SLICE = {
  conference_end_headset: 'End of headset conference: {{name}}',
  task_complite: 'Task completed',
  task_complite_description: '(0% - not completed, 100% - fully completed)',
  headset_communication_quality: 'Headset communication quality',
  headset_communication_quality_description: '(0 - poor, 5 - excellent)',
  pleasant_communication: 'How pleasant it is to communicate with the expert',
  pleasant_communication_description: '(0 - not pleasant, 5 - very pleasant)',
  quality_expertise: 'Quality of expertise',
  quality_expertise_description: '(0 - poor, 5 - excellent)',
  comment: 'Comment',
  enter_comment: 'Enter comment',
  next: 'Continue',
  your_rating: 'Your rating: {{rating}}',
  message: 'Congratulations, you\nearned {{amount}} rubles.\nThank you for your work!',
  finish: 'Finish',
};
