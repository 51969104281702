import React, { useState, useRef, FC } from 'react';
import { FiPaperclip } from 'react-icons/fi';
import { MdInsertDriveFile, MdPhotoCamera } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useCreateAttachment } from 'api';
import { CreateAttachmentResponse, CreateAttachmentParams } from 'api/endpoints/attachment/create-attachment';
import { TriggerWithArgs } from 'swr/mutation';
import { TFile } from './bottom-block';
import style from './styles.module.scss';

type CreateAttachment = TriggerWithArgs<CreateAttachmentResponse, any, '/api/v1/attachment/', CreateAttachmentParams>;

const loadFile = (file: File, createAttachment: CreateAttachment) => {
  return new Promise(async (res: ({ id, file }: TFile) => void, rej) => {
    try {
      const formData = new FormData();
      formData.append('data', file);
      const { id } = await createAttachment({ formData });
      res({ id, file });
    } catch (e) {
      rej(e);
    }
  });
};

const MAX_COUNT_FILES = 10;

type ToolsPupopProps = {
  files: TFile[];
  setFiles: React.Dispatch<React.SetStateAction<TFile[]>>;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingFiles: React.Dispatch<React.SetStateAction<boolean>>;
};

const ToolsPupop: FC<ToolsPupopProps> = ({ files, setFiles, setIsOpenPopup, setIsLoadingFiles }) => {
  const { trigger: createAttachment } = useCreateAttachment();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputPhotoRef = useRef<HTMLInputElement>(null);

  const handleClickOnFile = () => {
    inputFileRef.current?.click();
  };

  const handleClickOnPhoto = () => {
    inputPhotoRef.current?.click();
  };

  const handleChangeFiles: React.ChangeEventHandler<HTMLInputElement> = async e => {
    setIsOpenPopup(false);
    const { files: selectFiles } = e.currentTarget;
    if (!selectFiles) return;

    if (files.length >= MAX_COUNT_FILES) {
      toast.error('Максимальное количество файлов 10');
      return;
    }

    const newState = [...files];
    const newStateLength = newState.length;
    const selectFilesLength = selectFiles.length;
    const filterSelectFiles: File[] = [];

    if (newStateLength + selectFilesLength > MAX_COUNT_FILES) {
      const sliceSelectFiles = [...selectFiles].slice(0, MAX_COUNT_FILES - newStateLength);
      filterSelectFiles.push(...sliceSelectFiles);
      toast.error('Максимальное количество файлов 10, поэтому некоторые файлы не были добавлены');
    } else {
      filterSelectFiles.push(...selectFiles);
    }

    try {
      setIsLoadingFiles(true);
      const newStateFiles = await Promise.all(filterSelectFiles.map(item => loadFile(item, createAttachment)));
      newState.push(...newStateFiles);
      setFiles(newState);
    } catch (e) {
      console.error(e);
      toast.error('Произошла ошибка при загрузке файлов. Повторите попытку');
    }
    setIsLoadingFiles(false);
  };

  return (
    <div className={style.toolsPupop}>
      <div className={style.item} onClick={handleClickOnFile}>
        <div className={style.imageBlock}>
          <MdInsertDriveFile color='black' size='18px' />
        </div>
        <div>Файл</div>
        <input className={style.inputFile} ref={inputFileRef} type='file' multiple onChange={handleChangeFiles} />
      </div>
      <div className={style.item} onClick={handleClickOnPhoto}>
        <div className={style.imageBlock}>
          <MdPhotoCamera color='black' size='18px' />
        </div>
        <div>Фотография</div>
        <input
          className={style.inputFile}
          ref={inputPhotoRef}
          type='file'
          multiple
          accept='image/*'
          onChange={handleChangeFiles}
        />
      </div>
    </div>
  );
};

type AttachmentsSelectorProps = {
  files: TFile[];
  isLoadingFiles: boolean;
  setFiles: React.Dispatch<React.SetStateAction<TFile[]>>;
  setIsLoadingFiles: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AttachmentsSelector: FC<AttachmentsSelectorProps> = ({
  files,
  isLoadingFiles,
  setFiles,
  setIsLoadingFiles,
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleToggleOpenPopup = () => setIsOpenPopup(state => !state);

  return (
    <button className={style.button} disabled={isLoadingFiles} onClick={handleToggleOpenPopup}>
      <FiPaperclip color={isLoadingFiles ? '#a3a3a3' : 'black'} size='24px' />
      {isOpenPopup && (
        <ToolsPupop
          files={files}
          setFiles={setFiles}
          setIsOpenPopup={setIsOpenPopup}
          setIsLoadingFiles={setIsLoadingFiles}
        />
      )}
    </button>
  );
};
