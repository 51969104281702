import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaVideo } from 'react-icons/fa6';
import { PiWarningCircleFill } from 'react-icons/pi';
import cn from 'classnames';
import { JanusJS } from 'janus-gateway';
import style from './controls.module.scss';

type VideoProps = {
  isActive: boolean;
  pluginHandle: JanusJS.PluginHandle;
  isHasAccess: boolean;
};

export const Video: FC<VideoProps> = ({ isActive, pluginHandle, isHasAccess }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isVideo, setIsVideo] = useState(isActive === false ? false : pluginHandle.isVideoMuted());

  //TODO: сделать, чтобы видео поток не отправлялся
  const handleToggleVideo = () => {
    if (isVideo) {
      pluginHandle.muteVideo();
      setIsVideo(false);
    } else {
      pluginHandle.unmuteVideo();
      setIsVideo(true);
    }
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isVideo || !isHasAccess })}
      title={t('controls.video', { status: isVideo ? t('off') : t('on') })}
      disabled={!isHasAccess}
      onClick={handleToggleVideo}
    >
      {!isHasAccess && (
        <PiWarningCircleFill className={style.warning} color='#facc15' size='20px' title='Камера не доступна' />
      )}
      <FaVideo size='20px' color='black' />
    </button>
  );
};
