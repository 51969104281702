import { createContext } from 'react';
import { WSMessage } from 'types';

export type TWebsocketMessageContext = {
  message: WSMessage | null;
};

export type TActionsWebsocketMessageContext = {
  setMessage: React.Dispatch<React.SetStateAction<TWebsocketMessageContext['message']>> | (() => null);
};

export const WebsocketMessageContext = createContext<TWebsocketMessageContext>({
  message: null,
});

export const ActionsWebsocketMessageContext = createContext<TActionsWebsocketMessageContext>({
  setMessage: () => null,
});
