import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, StarsBlock } from 'components';
import { PRIVATE_CABINET } from 'constants/path';
import style from './final.module.scss';

//TODO: get from back
const RATING = 4.3;
const AMOUNT = 2000;

export const Final = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'finish_meeting' });
  const navigate = useNavigate();

  const handleFinish = () => {
    navigate(PRIVATE_CABINET);
  };

  return (
    <div className={style.container}>
      <div className={style.rating}>{t('your_rating', { rating: RATING })}</div>
      <StarsBlock size={42} />
      <div className={style.discription}>{t('message', { amount: AMOUNT })}</div>
      <Button classNames={{ button: style.button }} label={t('finish')} onClick={handleFinish} />
    </div>
  );
};
