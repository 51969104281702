export const LAYOUTS_SLICE = {
  'private-cabinet-layout': {
    activateAccount: 'Activate account',
    user: 'User',
    employee: 'employee',
    admin: 'administrator',
    yourBalance: 'Your balance: {{balance}}',
    exit: 'Exit',
  },
};
