import React, { FC } from 'react';
import cn from 'classnames';
import style from './tabs.module.scss';

type TabItemProps = {
  id: string;
  label: string;
  activeId: string;
  width: string;
  tabClassName?: string;
  onChange: (activeId: string) => void;
};

export const TabItem: FC<TabItemProps> = ({ id, label, activeId, width, tabClassName, onChange }) => {
  const isActive = id === activeId;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    const { id } = e.currentTarget;
    onChange(id);
  };

  return (
    <div
      className={cn(style.tab, { [style.tab__active]: isActive }, tabClassName)}
      style={{ width }}
      id={id}
      onClick={handleClick}
    >
      {label}
    </div>
  );
};
