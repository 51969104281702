import React, { FC, useMemo } from 'react';
import { FaStar } from 'react-icons/fa';
import style from './stars-block.module.scss';

//TODO: get from back
const RATING = 4.3;
const COUNT_STARTS = 5;

type StarsBlockProps = {
  size: number;
};

export const StarsBlock: FC<StarsBlockProps> = ({ size }) => {
  const startsDisplay = useMemo(() => {
    const starts = [];
    let rating = RATING;

    for (let count = COUNT_STARTS; count > 0; --count) {
      let width = 0;

      if (rating < 1) {
        width = (1 - rating) * 100;
        rating = 0;
      } else {
        --rating;
      }

      starts.push(
        <div className={style.starContainer} style={{ width: size, height: size }} key={count}>
          <div className={style.darkening} style={{ width: `${width}%` }}></div>
          <FaStar size={size} color='#fde047' />
        </div>,
      );
    }

    return starts;
  }, [size]);

  return <div className={style.starts}>{startsDisplay}</div>;
};
