import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSquare } from 'react-icons/fa';
import { MdFiberManualRecord } from 'react-icons/md';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './controls.module.scss';

type RecordProps = {
  socket: Socket;
};

export const Record: FC<RecordProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isRecord, setIsRecord] = useState(false);

  socket.on('record_state', value => {
    setIsRecord(value);
  });

  const handleToggle = () => {
    socket.emit('record_set', !isRecord);
    setIsRecord(!isRecord);
  };

  return (
    <button
      className={cn(style.button, { [style.button__record]: isRecord })}
      title={t('controls.record', { status: isRecord ? t('off') : t('on') })}
      onClick={handleToggle}
    >
      {isRecord ? <FaSquare size='20px' color='red' /> : <MdFiberManualRecord size='20px' color='black' />}
    </button>
  );
};
