import { fetcher, Method, CONFIRM_RESTORE, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

type ConfirmRestoreBody = {
  code: string;
  password: string;
};

type ConfirmRestoreResponse = User;

const confirmRestore = async (path: string, { arg }: { arg: ConfirmRestoreBody }) =>
  fetcher<ConfirmRestoreResponse>({ path, method: Method.POST, body: arg });

export const useConfirmRestore = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(CONFIRM_RESTORE, confirmRestore, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
