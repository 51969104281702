import { fetcher, Method, HEADSET_AS_USER, HEADSET, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Headset } from 'types';

type CreateHeadsetAsUserBody = {
  as_user_id: string;
};

type CreateHeadsetAsUserResponse = Headset;

const createHeadsetAsUser = async (path: string, { arg }: { arg: CreateHeadsetAsUserBody }) =>
  fetcher<CreateHeadsetAsUserResponse>({ path, method: Method.POST, body: arg });

export const useCreateHeadsetAsUser = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(HEADSET_AS_USER, createHeadsetAsUser, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && (key.startsWith(HEADSET) || key.startsWith(USER)));
    },
  });
};
