import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton, Textarea } from 'components';
import style from './block.module.scss';

const COMPLITE_DATA = [
  {
    nameGroup: 'complite',
    label: '100%',
    id: '100',
  },
  {
    nameGroup: 'complite',
    label: '75%',
    id: '75',
  },
  {
    nameGroup: 'complite',
    label: '50%',
    id: '50',
  },
  {
    nameGroup: 'complite',
    label: '25%',
    id: '25',
  },
  {
    nameGroup: 'complite',
    label: '0%',
    id: '0',
  },
];

type CompleteBlockProps = {
  value: string;
  comment: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
};

export const CompleteBlock: FC<CompleteBlockProps> = ({ value, comment, setValue, setComment }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'finish_meeting' });

  const display = useMemo(
    () =>
      COMPLITE_DATA.map(({ nameGroup, label, id }) => {
        const isChecked = id === value;

        return (
          <RadioButton
            key={id}
            classNames={{ block: style.item }}
            id={id}
            isChecked={isChecked}
            label={label}
            nameGroup={nameGroup}
            onChange={setValue}
          />
        );
      }),
    [value, setValue],
  );

  return (
    <div className={style.block}>
      <div className={style.conteiner}>
        <div>
          <div className={style.title}>{t('task_complite')}</div>
          <div className={style.description}>{t('task_complite_description')}</div>
        </div>
        <div className={style.radioButtonsContainer}>{display}</div>
      </div>
      <Textarea label={t('comment')} placeholder={t('enter_comment')} value={comment} onChange={setComment} />
    </div>
  );
};
