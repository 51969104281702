import { createContext } from 'react';

export type TIsAuthContext = {
  isAuth: boolean;
};

export type TActionsIsAuthContext = {
  setIsAuth: React.Dispatch<React.SetStateAction<TIsAuthContext['isAuth']>> | (() => null);
};

export const IsAuthContext = createContext<TIsAuthContext>({
  isAuth: false,
});

export const ActionsIsAuthContext = createContext<TActionsIsAuthContext>({
  setIsAuth: () => null,
});
