import React, { FC, useEffect, useRef, useState } from 'react';
import { IoSend } from 'react-icons/io5';
import { Socket } from 'socket.io-client';
import { AttachmentsSelector, AttachmentsBlock } from './';
import style from './styles.module.scss';

export type TFile = {
  id: string;
  file: File;
};

type BottomBlockProps = {
  socket: Socket;
  chatId: string;
};

export const BottomBlock: FC<BottomBlockProps> = ({ socket, chatId }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState('');
  const [files, setFiles] = useState<TFile[]>([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  useEffect(() => {
    const { current } = textareaRef;
    if (!current) return;
    setText('');
    setFiles([]);
    current.style.height = 'auto';
  }, [chatId]);

  const handleToggleFocus = () => {
    textareaRef.current?.focus();
  };

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    const { value } = e.currentTarget;
    setText(value);
  };

  const handleAutoResize = () => {
    const { current } = textareaRef;
    if (!current) return;
    current.style.height = 'auto';
    current.style.height = current.scrollHeight - 4 + 'px';
  };

  const handleSend = () => {
    const attachedIds = files.map(({ id }) => id);
    socket.emit('send_message', {
      text,
      attached_ids: attachedIds,
    });

    setText('');
    setFiles([]);

    const { current } = textareaRef;
    if (!current) return;
    current.style.height = 'auto';
    handleToggleFocus();
  };

  const isDisabledButtonSend = (!text && !files.length) || isLoadingFiles;

  return (
    <div className={style.bottomBlock}>
      <div className={style.attachmentsBlockContainer}>
        {!!files.length && <AttachmentsBlock files={files} setFiles={setFiles} />}
      </div>
      <div className={style.row}>
        <AttachmentsSelector
          files={files}
          isLoadingFiles={isLoadingFiles}
          setFiles={setFiles}
          setIsLoadingFiles={setIsLoadingFiles}
        />
        <div className={style.textareaConteiner} onClick={handleToggleFocus}>
          <textarea
            className={style.textarea}
            ref={textareaRef}
            value={text}
            placeholder='Введите сообщение'
            rows={1}
            onInput={handleAutoResize}
            onChange={handleChange}
          />
        </div>
        <button className={style.button} disabled={isDisabledButtonSend} onClick={handleSend}>
          <IoSend color={isDisabledButtonSend ? '#a3a3a3' : 'black'} size='24px' />
        </button>
      </div>
    </div>
  );
};
