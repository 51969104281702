import { fetcher, Method, VIDEO } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

type DeleteVideoParams = {
  id: string;
};

type DeleteVideoResponse = {};

const deleteVideo = async (path: string, { arg: { id } }: { arg: DeleteVideoParams }) =>
  fetcher<DeleteVideoResponse>({ path: `${path}/${id}/`, method: Method.DELETE });

export const useDeleteVideo = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(VIDEO, deleteVideo, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(VIDEO));
    },
  });
};
