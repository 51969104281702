import { fetcher, Method, HEADSET } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export type DeleteHeadsetParams = {
  id: string;
};

export type DeleteHeadsetResponse = undefined;

const deleteHeadset = async (path: string, { arg }: { arg: DeleteHeadsetParams }) =>
  fetcher<DeleteHeadsetResponse>({ path: `${path}${arg.id}/`, method: Method.DELETE });

export const useDeleteHeadset = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(HEADSET, deleteHeadset, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(HEADSET));
    },
  });
};
