import React, { useEffect, useMemo, useState } from 'react';
import data from 'date-and-time';
import style from './timer.module.scss';

const TIME = 1000;
const MILLISECOND_HOUR = 60 * 60 * 1000;

export const Timer = ({ isPause }: { isPause: boolean }) => {
  const [timeMilliseconds, setTimeMilliseconds] = useState(0);

  const formatTime = useMemo(() => {
    const date = new Date(timeMilliseconds);

    let formatString = '';

    if (timeMilliseconds >= MILLISECOND_HOUR) {
      formatString = 'HH:mm:ss';
    } else {
      formatString = 'mm:ss';
    }

    const time = data.format(date, formatString, true);
    return time;
  }, [timeMilliseconds]);

  useEffect(() => {
    if (isPause) return;

    const id = setInterval(() => {
      setTimeMilliseconds(state => (state += TIME));
    }, TIME);

    return () => {
      clearInterval(id);
    };
  }, [isPause]);

  return <div className={style.block}>{formatTime}</div>;
};
