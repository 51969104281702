export const REGISTRATION_SLICE = {
  successMessage: 'Пароль и код подтверждения отправлены на почту {{email}}',
  title: 'Регистрация',
  loginLabel: 'Логин',
  loginPlaceholder: 'Введите логин',
  phoneLabel: 'Телефон',
  phonePlaceholder: 'Введите телефон',
  emailLabel: 'Электронная почта',
  emailPlaceholder: 'Введите электронную почту',
  componyNameLabel: 'Название компании',
  componyNamePlaceholder: 'Введите название компании',
  registrationButton: 'Зарегистрироваться',
};
