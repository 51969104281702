import { createContext } from 'react';

export type TIsVisibleHeaderContext = {
  isVisible: boolean;
};

export type TActionsIsVisibleHeaderContext = {
  setIsVisible: React.Dispatch<React.SetStateAction<TIsVisibleHeaderContext['isVisible']>> | (() => null);
};

export const IsVisibleHeaderContext = createContext<TIsVisibleHeaderContext>({
  isVisible: true,
});

export const ActionsIsVisibleHeaderContext = createContext<TActionsIsVisibleHeaderContext>({
  setIsVisible: () => null,
});
