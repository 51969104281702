export const MODALS_SLICE = {
  'add-headset-modal': {
    successMessage: 'Гарнитура успешно создана',
    title: 'Добавить гарнитуру',
    nameLabel: 'Название',
    namePlaceholder: 'Введите название',
    idPlaceholder: 'Введите ID',
    versionLabel: 'Версия',
    versionPlaceholder: 'Выберите версию',
    keyLabel: 'Ключ',
    keyPlaceholder: 'Введите ключ',
    close: 'Закрыть',
    add: 'Добавить',
  },
  'add-user-modal': {
    successMessage: 'Пользователь успешно создан',
    title: 'Добавить пользователя',
    loginLabel: 'Логин',
    loginPlaceholder: 'Введите логин',
    close: 'Закрыть',
    add: 'Добавить',
  },
  'change-headset-modal': {
    successMessage: 'Данные гарнитуры успешно изменены',
    title: 'Изменить гарнитуру',
    nameLabel: 'Название',
    namePlaceholder: 'Введите название',
    idPlaceholder: 'Введите ID',
    versionLabel: 'Версия',
    versionPlaceholder: 'Выберите версию',
    keyLabel: 'Ключ',
    keyPlaceholder: 'Введите ключ',
    close: 'Закрыть',
    change: 'Изменить',
    delete: 'Удалить гарнитуру',
  },
  'change-user-modal': {
    userSuccessMessage: 'Данные пользователя успешно изменены',
    headsetSuccessMessage: 'Гарнитура успешно создана',
    title: 'Пользователь {{username}}',
    hasHeadset: 'Есть смартфон-гарнитура',
    createHeadset: 'Создать смартфон-гарнитуру',
    statusesLabel: 'Статус пользователя:',
    statuses: {
      active: 'Активный',
      notActive: 'Выключен',
    },
    close: 'Закрыть',
    save: 'Сохранить',
  },
  'check-list-modal': {
    close: 'Закрыть',
  },
  'confirm-update-user-password-modal': {
    title: 'Вы дейставитьно хотите обновить пароль у пользователя {{username}}?',
    update: 'Обновить',
    cancel: 'Отменить',
  },
  'confirm-user-registration': {
    successMessage: 'Регистрация успешно подтверждена',
    title: 'Подтверждение регистрации',
    codeLabel: 'Код из письма',
    codePlaceholder: 'Введите код',
    close: 'Закрыть',
    confirm: 'Подтвердить',
  },
  'delete-headset-modal': {
    successMessage: 'Гарнитура успешно удалена',
    title: 'Удаление гарнитуры',
    discription: 'Вы действительно хотите удалить гарнитуру {{name}}?',
    close: 'Закрыть',
    delete: 'Удалить',
  },
  'edit-snapshot-modal': {
    successMessade: 'Успешно сохранено',
    errorMessade: 'Не удалось сохранить изменения. {{error}}',
    title: 'Редактори снепшота',
    close: 'Закрыть редактор',
    save: 'Сохранить изменения',
    color: {
      red: 'Красный',
      blue: 'Синий',
      black: 'Черный',
      green: 'Зеленый',
      pink: 'Розовый',
    },
  },
  'exit-video-room': {
    finish: 'Завершить',
    pause: 'Поставить на паузу',
    cancel: 'Отменить',
  },
  'new-user-information-modal': {
    title: 'Новый пользователя создан',
    login: 'Логин:',
    password: 'Пароль:',
    close: 'Закрыть',
  },
  'photo-modal': {
    close: 'Закрыть',
  },
  'update-user-password-modal': {
    successMessage: 'Пароль успешно обновлен',
    title: 'Пароль обновлен',
    login: 'Логин:',
    password: 'Новый пароль:',
    close: 'Закрыть',
  },
};
