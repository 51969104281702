import React, { FC } from 'react';
import cn from 'classnames';
import style from './textarea.module.scss';

type TextareaProps = {
  value: string;
  label?: string;
  placeholder?: string;
  classNames?: { block?: string; label?: string; textarea?: string };
  onChange: (value: string) => void;
};

export const Textarea: FC<TextareaProps> = ({ label, placeholder, classNames, value, onChange }) => {
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    const { value } = e.currentTarget;
    onChange(value);
  };

  return (
    <div className={cn(style.block, classNames?.block)}>
      {label && <div className={cn(style.label, classNames?.label)}>{label}</div>}
      <textarea
        className={cn(style.textarea, classNames?.textarea)}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
