import { fetcher, Method, ATTACHMENT } from 'api';
import useSWRMutation from 'swr/mutation';

export type CreateAttachmentParams = {
  formData: FormData;
};

export type CreateAttachmentResponse = {
  id: string;
  url: string;
  name: string;
  created_at: string;
};

const createAttachment = async (path: string, { arg: { formData } }: { arg: CreateAttachmentParams }) =>
  fetcher<CreateAttachmentResponse>({ path, method: Method.POST, formData });

export const useCreateAttachment = () => useSWRMutation(ATTACHMENT, createAttachment);
