import { useState, useEffect } from 'react';

export const useIntersection = (target: React.RefObject<Element>, options?: IntersectionObserverInit) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const { current } = target;
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (current) {
      observer.observe(current);

      return () => observer.unobserve(current);
    }
  }, [target, options]);

  return isVisible;
};
