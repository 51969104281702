import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './failed-banner.module.scss';

export const FailedBanner = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  return (
    <div className={style.banner}>
      <div className={style.content}>{t('failedBanner')}</div>
    </div>
  );
};
