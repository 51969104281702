import React, { FC, useState } from 'react';
import { Notification, Button, EditSnapshotModal } from 'components';
import style from './snapshot-notification.module.scss';

type SnapshotNotificationProps = {
  src: string;
  href: string;
  onClose: () => void;
};

export const SnapshotNotification: FC<SnapshotNotificationProps> = ({ src, href, onClose }) => {
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);

  const handleOpenModal = () => setIsOpenModalEdit(true);
  const handleCloseModal = () => {
    setIsOpenModalEdit(false);
    onClose();
  };

  return (
    <>
      <Notification onClose={onClose}>
        <div className={style.block}>
          <a className={style.content} target='noreferrer' href={href} onClick={onClose}>
            <img className={style.img} alt='Снепшот' src={src} />
          </a>
          <Button classNames={{ button: style.button }} label='Изменить' onClick={handleOpenModal} />
        </div>
      </Notification>
      {isOpenModalEdit && <EditSnapshotModal src={src} href={href} onClose={handleCloseModal} />}
    </>
  );
};
