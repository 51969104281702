export const FINISH_MEETING_SLICE = {
  conference_end_headset: 'Kulaklık konferansının sonu: {{name}}',
  task_complite: 'Görev tamamlandı',
  task_complite_description: '(0% - tamamlanmadı, 100% - tamamen tamamlandı)',
  headset_communication_quality: 'Kulaklık iletişim kalitesi',
  headset_communication_quality_description: '(0 - kötü, 5 - mükemmel)',
  pleasant_communication: 'Uzmanla iletişim kurmanın ne kadar hoş olduğu',
  pleasant_communication_description: '(0 - hoş değil, 5 - çok hoş)',
  quality_expertise: 'Uzmanlık kalitesi',
  quality_expertise_description: '(0 - kötü, 5 - mükemmel)',
  comment: 'Yorum',
  enter_comment: 'Yorum girin',
  next: 'Devam et',
  your_rating: 'Puanınız: {{rating}}',
  message: 'Tebrikler, {{amount}} ruble kazandınız.\nİşiniz için teşekkürler!',
  finish: 'Bitir',
};
