import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { TabItem } from './tab-item';
import style from './tabs.module.scss';

type TabsProps = {
  data: { label: string; id: string }[];
  activeId: string;
  label?: string;
  classNames?: { container?: string; label?: string; block?: string; tab?: string };
  onChange: (activeId: string) => void;
};

export const Tabs: FC<TabsProps> = ({ label, data, activeId, classNames, onChange }) => {
  const displayTabs = useMemo(() => {
    const dataLength = data.length;
    const widthTab = 100 / dataLength;
    const widthSpace = (dataLength - 1) * 2;
    const width = `calc(${widthTab}% - ${widthSpace}px)`;

    return data.map(({ id, label }) => (
      <TabItem
        key={id}
        id={id}
        label={label}
        activeId={activeId}
        width={width}
        tabClassName={classNames?.tab}
        onChange={onChange}
      />
    ));
  }, [data, classNames, activeId, onChange]);

  return (
    <div className={classNames?.container}>
      {label && <div className={cn(style.label, classNames?.label)}>{label}</div>}
      <div className={cn(style.block, classNames?.block)}>{displayTabs}</div>
    </div>
  );
};
