export const MODALS_SLICE = {
  'add-headset-modal': {
    successMessage: '耳机成功创建',
    title: '添加耳机',
    nameLabel: '名称',
    namePlaceholder: '输入名称',
    idPlaceholder: '输入ID',
    versionLabel: '版本',
    versionPlaceholder: '选择版本',
    keyLabel: '键',
    keyPlaceholder: '输入键',
    close: '关闭',
    add: '添加',
  },
  'add-user-modal': {
    successMessage: '用户成功创建',
    title: '添加用户',
    loginLabel: '登录',
    loginPlaceholder: '输入登录名',
    close: '关闭',
    add: '添加',
  },
  'change-headset-modal': {
    successMessage: '耳机数据成功更改',
    title: '更改耳机',
    nameLabel: '名称',
    namePlaceholder: '输入名称',
    idPlaceholder: '输入ID',
    versionLabel: '版本',
    versionPlaceholder: '选择版本',
    keyLabel: '键',
    keyPlaceholder: '输入键',
    close: '关闭',
    change: '更改',
    delete: '删除耳机',
  },
  'change-user-modal': {
    userSuccessMessage: '用户数据成功更改',
    headsetSuccessMessage: '耳机成功创建',
    title: '用户 {{username}}',
    hasHeadset: '拥有智能手机耳机',
    createHeadset: '创建智能手机耳机',
    statusesLabel: '用户状态：',
    statuses: {
      active: '活跃',
      notActive: '已停用',
    },
    close: '关闭',
    save: '保存',
  },
  'check-list-modal': {
    close: '关闭',
  },
  'confirm-update-user-password-modal': {
    title: '你真的想更新用户 {{username}} 的密码吗？',
    update: '更新',
    cancel: '取消',
  },
  'confirm-user-registration': {
    successMessage: '注册成功确认',
    title: '注册确认',
    codeLabel: '信中的代码',
    codePlaceholder: '输入代码',
    close: '关闭',
    confirm: '确认',
  },
  'delete-headset-modal': {
    successMessage: '耳机成功删除',
    title: '删除耳机',
    discription: '你真的想删除耳机 {{name}} 吗？',
    close: '关闭',
    delete: '删除',
  },
  'edit-snapshot-modal': {
    successMessade: '成功保存',
    errorMessade: '保存更改失败。{{error}}',
    title: '快照编辑器',
    close: '关闭编辑器',
    save: '保存更改',
    color: {
      red: '红色',
      blue: '蓝色',
      black: '黑色',
      green: '绿色',
      pink: '粉色',
    },
  },
  'exit-video-room': {
    finish: '完成',
    pause: '暂停',
    cancel: '取消',
  },
  'new-user-information-modal': {
    title: '新用户创建',
    login: '登录：',
    password: '密码：',
    close: '关闭',
  },
  'photo-modal': {
    close: '关闭',
  },
  'update-user-password-modal': {
    successMessage: '密码成功更新',
    title: '更新密码',
    login: '登录：',
    password: '新密码：',
    close: '关闭',
  },
};
