import { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ID_ROOT_NOTIFICATION_ELEMENT } from 'constants/constants';

type NotificationPortalProps = {
  children: ReactNode;
};

export const NotificationPortal: FC<NotificationPortalProps> = ({ children }) => {
  const [rootNotification, setRootNotification] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const rootNotification = document.getElementById(ID_ROOT_NOTIFICATION_ELEMENT);
    setRootNotification(rootNotification);
  }, []);

  return <>{rootNotification && createPortal(children, rootNotification)}</>;
};
