import { fetcher, Method, CONFIRM_REGISTRATION, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

type ConfirmUserRegistrationBody = {
  code: string;
};

type ConfirmUserRegistrationResponse = User;

const confirmUserRegistration = (path: string, { arg }: { arg: ConfirmUserRegistrationBody }) =>
  fetcher<ConfirmUserRegistrationResponse>({ path, method: Method.POST, body: arg });

export const useConfirmUserRegistration = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(CONFIRM_REGISTRATION, confirmUserRegistration, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
