import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetHeadsets } from 'api';
import { DropdownSelect } from 'components';
import { QUERY } from 'constants/path';
import style from './headset-selector.module.scss';

type HeadsetSelectorProps = {
  name: string;
};

export const HeadsetSelector: FC<HeadsetSelectorProps> = ({ name }) => {
  const [query, setQuery] = useSearchParams();
  const { data } = useGetHeadsets({});
  const [label, setLabel] = useState(name);

  const list = useMemo(() => data?.results?.map(({ id, name }) => ({ id, label: name })) ?? [], [data]);

  const queryHeadsetId = query.get(QUERY.headsetId);

  useEffect(() => {
    const headset = list.find(item => item.id === queryHeadsetId);
    if (headset) {
      setLabel(headset?.label);
    }
  }, [queryHeadsetId]);

  const handleChange = (id: string) => {
    setQuery({ [QUERY.headsetId]: id });
  };

  return (
    <div className={style.block}>
      <DropdownSelect dataList={list} value={label} onChange={handleChange} />
    </div>
  );
};
