import { fetcher, Method, VIDEO } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

type GetVideoParams = {
  headsetId?: string;
  limit?: number;
  offset?: number;
};

export type GetVideoResponce = {
  count: number;
  next: string;
  previous: string;
  results: {
    id: string;
    url: string;
    created_at: string;
  }[];
};

const getVideo = async (path: string) => fetcher<GetVideoResponce>({ path, method: Method.GET });

export const useGetVideo = (params: GetVideoParams) =>
  useSWR(`${VIDEO}?headset=${params.headsetId}&limit=${params.limit}&offset=${params.offset}`, getVideo);

export const useInfinityGetVideo = (params: Omit<GetVideoParams, 'offset'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const offset = pageIndex * (params.limit ?? 20);

    return `${VIDEO}?headset=${params.headsetId}&limit=${params.limit}&offset=${offset}`;
  }, getVideo);
