import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiHandFistLight } from 'react-icons/pi';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './controls.module.scss';

type FixProps = {
  socket: Socket;
};

export const Fix: FC<FixProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const [isFix, setIsFix] = useState(false);

  socket.on('view_fixed', value => {
    setIsFix(value);
  });

  const handleToggle = () => {
    socket?.emit('view_fixed', !isFix);
    setIsFix(!isFix);
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isFix })}
      title={t('controls.fix', { status: isFix ? t('off') : t('on') })}
      onClick={handleToggle}
    >
      <PiHandFistLight size='20px' color='black' />
    </button>
  );
};
