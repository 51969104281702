export const LAYOUTS_SLICE = {
  'private-cabinet-layout': {
    activateAccount: 'Hesabı etkinleştir',
    user: 'Kullanıcı',
    employee: 'çalışan',
    admin: 'yönetici',
    yourBalance: 'Bakiyeniz: {{balance}}',
    exit: 'Çıkış',
  },
};
