import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeleteVideo } from 'api';
import { GetVideoResponce } from 'api/endpoints/video/get-video';
import { VisibleIcon, DeleteIcon } from 'assets';
import style from '../headset-details.module.scss';

type HeadsetVideoItemProps = {
  video: GetVideoResponce['results'][0];
  columnItemRef?: React.RefObject<HTMLDivElement>;
  setVideo: React.Dispatch<React.SetStateAction<GetVideoResponce['results']>>;
};

export const HeadsetVideoItem: FC<HeadsetVideoItemProps> = ({ video, columnItemRef, setVideo }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'headset_details' });
  const { trigger: deleteVideo } = useDeleteVideo();
  const { created_at: title, url, id } = video;

  const handleDelete = async () => {
    try {
      await deleteVideo({ id });
      setVideo(state => state.filter(item => item.id !== id));
      toast.success('Видео успешно удалено');
    } catch (e) {
      toast.error('При удалении видео произошла ошибка');
      console.error(e);
    }
  };

  return (
    <div className={style.columnItem} ref={columnItemRef}>
      <div className={style.point}></div>
      <div className={style.name}>{title}</div>
      <div className={style.buttons}>
        <a className={style.button} title={t('visible_button_title')} target='noreferrer' href={url}>
          <VisibleIcon width='20px' height='20px' />
        </a>
        {/* 
          //ошибки никакой нет, но ts почему-то ругается
          //@ts-ignore
        */}
        <button className={style.button} title={t('delete_button_title')} onClick={handleDelete}>
          <DeleteIcon width='20px' height='20px' />
        </button>
      </div>
    </div>
  );
};
