import { fetcher, Method, HEADSET } from 'api';
import useSWR from 'swr';
import { Headset } from 'types';

type GetHeadsetParams = {
  id?: string | null;
};

type GetHeadsetResponse = Headset;

const getHeadset = async (path: string) => fetcher<GetHeadsetResponse>({ path, method: Method.GET });

export const useGetHeadset = (params: GetHeadsetParams) => useSWR(`${HEADSET}${params.id}/`, getHeadset);
