import { fetcher, Method, LOGIN } from 'api';
import useSWRMutation from 'swr/mutation';

type LoginBody = {
  username: string;
  password: string;
};

type LoginResponse = {
  expiry: string;
  token: string;
  user: {
    id: string;
    username: string;
    parent: string | null;
    email: string;
    company_name: string;
    phone: string;
    is_active: boolean;
    is_verify: boolean;
  };
};

const login = async (path: string, { arg }: { arg: LoginBody }) =>
  fetcher<LoginResponse>({ path, method: Method.POST, body: arg });

export const useLogin = () => useSWRMutation(LOGIN, login);
