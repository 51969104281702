export const REGISTRATION_SLICE = {
  successMessage: 'Password and confirmation code sent to email {{email}}',
  title: 'Registration',
  loginLabel: 'Login',
  loginPlaceholder: 'Enter login',
  phoneLabel: 'Phone',
  phonePlaceholder: 'Enter phone',
  emailLabel: 'Email',
  emailPlaceholder: 'Enter email',
  componyNameLabel: 'Company name',
  componyNamePlaceholder: 'Enter company name',
  registrationButton: 'Register',
};
