export const RANGES_DATA = [
  {
    id: 'mouse_sensitivity',
    min: 1,
    max: 20,
    step: 1,
    labelValue: '',
  },
  {
    id: 'keyboard_sensitivity',
    min: 1,
    max: 20,
    step: 1,
    labelValue: '',
  },
  {
    id: 'depth_view',
    min: 1,
    max: 4,
    step: 0.1,
    labelValue: 'М.',
  },
] as const;
