import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import style from './controls.module.scss';

type FullscreenProps = {
  isFullscreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Fullscreen: FC<FullscreenProps> = ({ isFullscreen, setIsFullScreen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggle = () => {
    setIsFullScreen(state => !state);
  };

  return (
    <button
      className={style.button}
      //ошибки никакой нет, но ts почему-то ругается
      //@ts-ignore
      title={t('controls.video', { status: isFullscreen ? t('wrap') : t('unwrap') })}
      onClick={handleToggle}
    >
      {isFullscreen ? <MdFullscreenExit size='24px' color='black' /> : <MdFullscreen size='24px' color='black' />}
    </button>
  );
};
