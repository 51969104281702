export const FINISH_MEETING_SLICE = {
  conference_end_headset: '耳机会议结束：{{name}}',
  task_complite: '任务完成',
  task_complite_description: '(0% - 未完成, 100% - 完全完成)',
  headset_communication_quality: '耳机通信质量',
  headset_communication_quality_description: '(0 - 较差, 5 - 优秀)',
  pleasant_communication: '与专家交流的愉悦程度',
  pleasant_communication_description: '(0 - 不愉快, 5 - 非常愉快)',
  quality_expertise: '专业质量',
  quality_expertise_description: '(0 - 较差, 5 - 优秀)',
  comment: '评论',
  enter_comment: '输入评论',
  next: '继续',
  your_rating: '您的评分：{{rating}}',
  message: '恭喜您，您赚了{{amount}}卢布。\n感谢您的工作！',
  finish: '结束',
};
