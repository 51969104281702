import React, { FC } from 'react';
import cn from 'classnames';
import { JanusJS } from 'janus-gateway';
import { Socket } from 'socket.io-client';
import { OtherContent } from 'pages/meeting/constants';
import { useKeyboardEvents } from 'pages/meeting/hooks';
import {
  Exit,
  Microphone,
  Flashlight,
  Laser,
  Fix,
  VideoFurther,
  Record,
  Arrow,
  Snapshot,
  /* VideoResolution, */
  Options,
  Chat,
  People,
  Fullscreen,
  Video,
  TestScreenshot,
  SelectBitrate,
  VisualEffects,
} from './controls';
import style from './controls-panel.module.scss';

type ControlsPanelProps = {
  socket: Socket | null;
  stream: MediaStream | null;
  pluginHandle: JanusJS.PluginHandle | null;
  typeOtherContent: OtherContent | null;
  isFullscreen: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  isShowVisualEffects: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowVisualEffects: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTypeOtherContent: React.Dispatch<React.SetStateAction<OtherContent | null>>;
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ControlsPanel: FC<ControlsPanelProps> = ({
  socket,
  stream,
  pluginHandle,
  typeOtherContent,
  isFullscreen,
  isHasAccessMicrophone,
  isHasAccessVideo,
  isActiveMicrophone,
  isActiveVideo,
  isShowVisualEffects,
  setIsFullScreen,
  setIsShowVisualEffects,
  setIsTypeOtherContent,
  setIsActiveMeeting,
  setPause,
}) => {
  useKeyboardEvents(socket);
  //const isMeHeadset =

  return (
    <div className={cn(style.block, { [style.block__fullscreen]: isFullscreen })}>
      <div className={style.container}>
        <Exit setIsActiveMeeting={setIsActiveMeeting} setPause={setPause} />
        {pluginHandle && (
          <>
            <Microphone isActive={isActiveMicrophone} isHasAccess={isHasAccessMicrophone} pluginHandle={pluginHandle} />
            <Video isActive={isActiveVideo} isHasAccess={isHasAccessVideo} pluginHandle={pluginHandle} />
            <SelectBitrate pluginHandle={pluginHandle} />
          </>
        )}
        {socket && (
          <>
            <Flashlight socket={socket} />
            <Laser socket={socket} />
            <Fix socket={socket} />
            <VideoFurther socket={socket} />
            <Record socket={socket} />
            <div className={style.arrowsBlock}>
              <Arrow socket={socket} id='left' />
              <Arrow socket={socket} id='up' />
              <Arrow socket={socket} id='right' />
              <Arrow socket={socket} id='down' />
            </div>
            <TestScreenshot stream={stream} />
            <Snapshot socket={socket} />
            {/* <VideoResolution socket={socket} /> */}
            <VisualEffects isShowVisualEffects={isShowVisualEffects} setIsShowVisualEffects={setIsShowVisualEffects} />
            <Fullscreen isFullscreen={isFullscreen} setIsFullScreen={setIsFullScreen} />
            <Chat typeOtherContent={typeOtherContent} setIsTypeOtherContent={setIsTypeOtherContent} />
            <People typeOtherContent={typeOtherContent} setIsTypeOtherContent={setIsTypeOtherContent} />
            <Options socket={socket} />
          </>
        )}
      </div>
    </div>
  );
};
