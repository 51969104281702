import React, { FC, useRef } from 'react';
import cn from 'classnames';
import style from './input.module.scss';

type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
> & {
  value: string;
  label?: string;
  placeholder?: string;
  classNames?: { block?: string; input?: string; label?: string };
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input: FC<InputProps> = ({ value, label, placeholder, classNames, onChange, ...props }) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const handleClickOnBlock = () => {
    ref?.current?.focus();
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    onChange?.(value, e);
  };

  return (
    <>
      {label && <div className={cn(style.label, classNames?.label)}>{label}</div>}
      <div className={cn(style.block, classNames?.block)} onClick={handleClickOnBlock}>
        <input
          {...props}
          className={cn(style.input, classNames?.input)}
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
