import React, { FC, ReactNode, useState } from 'react';
import { IsVisibleHeaderContext, ActionsIsVisibleHeaderContext } from 'contexts';

type IsVisibleHeaderProviderProps = {
  children: ReactNode;
};

export const IsVisibleHeaderProvider: FC<IsVisibleHeaderProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <IsVisibleHeaderContext.Provider value={{ isVisible }}>
      <ActionsIsVisibleHeaderContext.Provider value={{ setIsVisible }}>
        {children}
      </ActionsIsVisibleHeaderContext.Provider>
    </IsVisibleHeaderContext.Provider>
  );
};
