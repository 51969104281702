import React, { FC, ReactNode, useState } from 'react';
import { WebsocketContext, ActionsWebsocketContext, TWebsocketContext } from 'contexts';

type WebsocketProviderProps = {
  children: ReactNode;
};

export const WebsocketProvider: FC<WebsocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<TWebsocketContext['socket']>(null);

  return (
    <WebsocketContext.Provider value={{ socket }}>
      <ActionsWebsocketContext.Provider value={{ setSocket }}>{children}</ActionsWebsocketContext.Provider>
    </WebsocketContext.Provider>
  );
};
