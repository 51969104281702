import React, { FC, useEffect, useState } from 'react';
import style from './video-stream.module.scss';

const TIME = 500;

type ElementsVideoStreamProps = {
  video: React.RefObject<HTMLVideoElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const ElementsVideoStream: FC<ElementsVideoStreamProps> = ({ video, onClick }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      const { current } = video;
      if (!current) return;

      const { clientWidth: containerWidth, clientHeight: containerHeight, videoWidth, videoHeight } = current;
      const originalResolution = videoWidth / videoHeight;
      const containedVideoWidth = originalResolution * containerHeight;
      const offsetWidth = (containerWidth - containedVideoWidth) / 2;

      setWidth(containedVideoWidth);
      setHeight(containerHeight);
      setLeft(offsetWidth);
    }, TIME);

    return () => {
      clearInterval(id);
    };
  }, [video]);

  const isVisibleElements = !!(width && height);

  return (
    <div className={style.elements} style={{ width, height, left }}>
      {isVisibleElements && (
        <div className={style.elementsContainer}>
          <div className={style.horizontalLine}></div>
          <div className={style.verticalLine}></div>
          <div className={style.circle}></div>
          {/* ДОЛЖЕН БЫТЬ ПОСЛЕДНИМ */}
          <div className={style.activeBlock} onClick={onClick}></div>
        </div>
      )}
    </div>
  );
};
