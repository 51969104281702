import React, { FC } from 'react';
import cn from 'classnames';
import style from './checkbox.module.scss';

type CheckboxProps = {
  id: string;
  isChecked: boolean;
  label?: string;
  classNames?: { block?: string; input?: string; label?: string };
  onChange: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox: FC<CheckboxProps> = ({ id, isChecked, label, classNames, onChange }) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.currentTarget;
    onChange(checked, e);
  };

  return (
    <div className={cn(style.block, classNames?.block)}>
      <input
        className={cn(style.input, classNames?.input)}
        type='checkbox'
        id={id}
        checked={isChecked}
        onChange={handleChange}
      />
      {label && (
        <label className={cn(style.label, classNames?.label)} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
