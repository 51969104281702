import { fetcher, Method, HEADSET } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { HeadsetVersion } from 'constants/constants';
import { Headset } from 'types';

export type UpdateHeadsetParams = {
  id: string;
  body: { name: string; uid: string; version: HeadsetVersion; key: string };
};

export type UpdateHeadsetResponse = Headset;

const updateHeadset = async (path: string, { arg: { id, body } }: { arg: UpdateHeadsetParams }) =>
  fetcher<UpdateHeadsetResponse>({ path: `${path}${id}/`, method: Method.PUT, body });

export const useUpdateHeadset = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(HEADSET, updateHeadset, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(HEADSET));
    },
  });
};
