import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircleCheck } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirmRestore } from 'api';
import { Button, Input } from 'components';
import style from './confirm-password-reset.module.scss';

export const ConfirmPasswordReset = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'confirm_password_reset' });
  const { trigger: confirmRestore } = useConfirmRestore();
  const { code } = useParams();
  const [password, setPassword] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  const handleResetPassword = async () => {
    if (!code) return;

    try {
      await confirmRestore({ code, password });
      toast.success(t('successMessage'));
      setIsChanged(true);
    } catch (e) {
      toast.error((e as { message: string }).message);
      console.error(e);
    }
  };

  const isDisabled = !(code && password);

  return (
    <div className={style.container}>
      <div className={style.title}>{t('title')}</div>
      {isChanged ? (
        <div className={style.content}>
          <FaCircleCheck size='82px' color='green' />
          <div className={style.text}>{t('passwordSuccessfull')}</div>
        </div>
      ) : (
        <>
          <Input label={t('newPassword')} placeholder={t('enterNewPassword')} value={password} onChange={setPassword} />
          <Button
            classNames={{ button: style.button }}
            label={t('update')}
            isDisabled={isDisabled}
            onClick={handleResetPassword}
          />
        </>
      )}
    </div>
  );
};
