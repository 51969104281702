import { fetcher, Method, SNAPSHOT } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

type DeleteSnapshotParams = {
  id: string;
};

type DeleteSnapshotResponse = {};

const deleteSnapshot = async (path: string, { arg: { id } }: { arg: DeleteSnapshotParams }) =>
  fetcher<DeleteSnapshotResponse>({ path: `${path}${id}/`, method: Method.DELETE });

export const useDeleteSnapshot = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(SNAPSHOT, deleteSnapshot, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(SNAPSHOT));
    },
  });
};
