import { Method, HEADSET, fetcher } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { Headset } from 'types';

type GetHeadsetsParams = {
  limit?: number;
  offset?: number;
};

type GetHeadsetsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Headset[];
};

const getHeadsets = (path: string) => fetcher<GetHeadsetsResponse>({ path, method: Method.GET });

export const useGetHeadsets = (params: GetHeadsetsParams) =>
  useSWR(`${HEADSET}?limit=${params?.limit}&offset=${params?.offset}`, getHeadsets);

export const useInfinityGetHeadsets = (params: Omit<GetHeadsetsParams, 'offset'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const offset = pageIndex * (params?.limit ?? 20);

    return `${HEADSET}?limit=${params?.limit}&offset=${offset}`;
  }, getHeadsets);
