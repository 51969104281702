import React, { FC, ReactNode, useState } from 'react';
import { WebsocketMessageContext, ActionsWebsocketMessageContext, TWebsocketMessageContext } from 'contexts';

type WebsocketMessageProviderProps = {
  children: ReactNode;
};

export const WebsocketMessageProvider: FC<WebsocketMessageProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<TWebsocketMessageContext['message']>(null);

  return (
    <WebsocketMessageContext.Provider value={{ message }}>
      <ActionsWebsocketMessageContext.Provider value={{ setMessage }}>
        {children}
      </ActionsWebsocketMessageContext.Provider>
    </WebsocketMessageContext.Provider>
  );
};
