import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowsAltH } from 'react-icons/fa';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './controls.module.scss';

type VideoFurtherProps = {
  socket: Socket;
};

export const VideoFurther: FC<VideoFurtherProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isTof, setIsTof] = useState(false);

  socket.on('tof', value => {
    setIsTof(value);
  });

  const handleToggle = () => {
    socket?.emit('tof', !isTof);
    setIsTof(!isTof);
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isTof })}
      title={t('controls.videoFurther', { status: isTof ? t('off') : t('on') })}
      onClick={handleToggle}
    >
      <FaArrowsAltH size='20px' color='black' />
    </button>
  );
};
