import React, { FC, useEffect, useState } from 'react';
import { getVideoResolution } from 'utils';
import style from './video-stream.module.scss';

const TIME = 1000;

type ResolutionVideoStreamProps = {
  video: React.RefObject<HTMLVideoElement>;
};

export const ResolutionVideoStream: FC<ResolutionVideoStreamProps> = ({ video }) => {
  const [resolution, setResolution] = useState(getVideoResolution(video.current));

  useEffect(() => {
    const id = setInterval(() => {
      const { current } = video;
      if (!current) return;
      const resolution = getVideoResolution(current);
      setResolution(resolution);
    }, TIME);

    return () => {
      clearInterval(id);
    };
  }, [video]);

  return <div className={style.videoResolution}>{resolution}</div>;
};
