export const MODALS_SLICE = {
  'add-headset-modal': {
    successMessage: 'Headset successfully created',
    title: 'Add headset',
    nameLabel: 'Name',
    namePlaceholder: 'Enter name',
    idPlaceholder: 'Enter ID',
    versionLabel: 'Version',
    versionPlaceholder: 'Select version',
    keyLabel: 'Key',
    keyPlaceholder: 'Enter key',
    close: 'Close',
    add: 'Add',
  },
  'add-user-modal': {
    successMessage: 'User successfully created',
    title: 'Add user',
    loginLabel: 'Login',
    loginPlaceholder: 'Enter login',
    close: 'Close',
    add: 'Add',
  },
  'change-headset-modal': {
    successMessage: 'Headset data successfully changed',
    title: 'Change headset',
    nameLabel: 'Name',
    namePlaceholder: 'Enter name',
    idPlaceholder: 'Enter ID',
    versionLabel: 'Version',
    versionPlaceholder: 'Select version',
    keyLabel: 'Key',
    keyPlaceholder: 'Enter key',
    close: 'Close',
    change: 'Change',
    delete: 'Delete headset',
  },
  'change-user-modal': {
    userSuccessMessage: 'User data successfully changed',
    headsetSuccessMessage: 'Headset successfully created',
    title: 'User {{username}}',
    hasHeadset: 'Has smartphone-headset',
    createHeadset: 'Create smartphone-headset',
    statusesLabel: 'User status:',
    statuses: {
      active: 'Active',
      notActive: 'Deactivated',
    },
    close: 'Close',
    save: 'Save',
  },
  'check-list-modal': {
    close: 'Close',
  },
  'confirm-update-user-password-modal': {
    title: 'Do you really want to update the password for user {{username}}?',
    update: 'Update',
    cancel: 'Cancel',
  },
  'confirm-user-registration': {
    successMessage: 'Registration successfully confirmed',
    title: 'Registration confirmation',
    codeLabel: 'Code from the letter',
    codePlaceholder: 'Enter code',
    close: 'Close',
    confirm: 'Confirm',
  },
  'delete-headset-modal': {
    successMessage: 'Headset successfully deleted',
    title: 'Delete headset',
    discription: 'Do you really want to delete the headset {{name}}?',
    close: 'Close',
    delete: 'Delete',
  },
  'edit-snapshot-modal': {
    successMessade: 'Successfully saved',
    errorMessade: 'Failed to save changes. {{error}}',
    title: 'Snapshot editor',
    close: 'Close editor',
    save: 'Save changes',
    color: {
      red: 'Red',
      blue: 'Blue',
      black: 'Black',
      green: 'Green',
      pink: 'Pink',
    },
  },
  'exit-video-room': {
    finish: 'Finish',
    pause: 'Pause',
    cancel: 'Cancel',
  },
  'new-user-information-modal': {
    title: 'New user created',
    login: 'Login:',
    password: 'Password:',
    close: 'Close',
  },
  'photo-modal': {
    close: 'Close',
  },
  'update-user-password-modal': {
    successMessage: 'Password successfully updated',
    title: 'Password updated',
    login: 'Login:',
    password: 'New password:',
    close: 'Close',
  },
};
