import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { PiWarningCircleFill } from 'react-icons/pi';
import cn from 'classnames';
import { JanusJS } from 'janus-gateway';
import style from './controls.module.scss';

type MicrophoneProps = {
  isActive: boolean;
  isHasAccess: boolean;
  pluginHandle: JanusJS.PluginHandle;
};

export const Microphone: FC<MicrophoneProps> = ({ isActive, isHasAccess, pluginHandle }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isMicrophone, setIsMicrophone] = useState(isActive === false ? false : pluginHandle.isAudioMuted());

  //TODO: сделать, чтобы видео поток не отправлялся
  const handleToggleMicrophone = () => {
    if (isMicrophone) {
      pluginHandle.muteAudio();
      setIsMicrophone(false);
    } else {
      pluginHandle.unmuteAudio();
      setIsMicrophone(true);
    }
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isMicrophone || !isHasAccess })}
      title={t('controls.mic', { status: isMicrophone ? t('off') : t('on') })}
      disabled={!isHasAccess}
      onClick={handleToggleMicrophone}
    >
      {!isHasAccess && (
        <PiWarningCircleFill className={style.warning} color='#facc15' size='20px' title={t('micNotFound')} />
      )}
      <HiOutlineMicrophone size='20px' color='black' />
    </button>
  );
};
