import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useGetHeadset } from 'api';
import { Socket } from 'socket.io-client';
import { QUERY } from 'constants/path';
import style from './controls.module.scss';

type SnapshotProps = {
  socket: Socket;
};

export const Snapshot: FC<SnapshotProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data } = useGetHeadset({ id: headsetId });
  const isUserHeadset = !!data?.as_user;

  const handleClick = () => {
    if (isUserHeadset) {
      //TODO: логика снепшота со смартфона
    } else {
      socket.emit('snapshot');
    }
  };

  return (
    <button className={cn(style.button, style.button__snapshot)} title={t('controls.snapshot')} onClick={handleClick}>
      Snapshot
    </button>
  );
};
