import React, { FC, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { getAuthorizationToken } from 'utils';
import { BottomBlock, MessagesBlock } from './components';
import style from './messenger.module.scss';

export type SocketMessage = {
  type: string;
  e: any;
};

type MessengerProps = {
  chatId: string;
};

export const Messenger: FC<MessengerProps> = ({ chatId }) => {
  const [socketEvent, setSocketEvent] = useState<SocketMessage | null>(null);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const token = getAuthorizationToken();
    const socket = io(`${process.env.REACT_APP_API_WEBSOCKET_URL}`, {
      path: '/ws/chat/',
      query: { headset: chatId },
      transports: ['websocket'],
      auth: {
        Authorization: token,
      },
      withCredentials: true,
    });

    socket.on('message', e => {
      setSocketEvent({ type: 'message', e });
    });

    socket.on('messages', e => {
      setSocketEvent({ type: 'messages', e });
    });

    socket.on('connect', () => {
      console.log('connect');
    });

    socket.on('error', e => {
      console.error(e);
    });

    //надо вызвать один раз
    socket.emit('history');

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [chatId]);

  return (
    <div className={style.container}>
      {socket && (
        <>
          <MessagesBlock socket={socket} socketEvent={socketEvent} chatId={chatId} />
          <BottomBlock socket={socket} chatId={chatId} />
        </>
      )}
    </div>
  );
};
