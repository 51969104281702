import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useConfirmUserRegistration } from 'api';
import { Modal, Button, Input } from 'components';
import style from './confirm-user-registration.module.scss';

type ConfirmUserRegistrationProps = {
  onClose: () => void;
};

export const ConfirmUserRegistration: FC<ConfirmUserRegistrationProps> = ({ onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.confirm-user-registration' });
  const { trigger: confirmUserRegistration } = useConfirmUserRegistration();
  const [code, setCode] = useState('');

  const handleConfirm = async () => {
    try {
      const body = { code };
      await confirmUserRegistration(body);
      onClose();
      toast.success(t('successMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const isDisabled = !code;

  return (
    <Modal title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <Input
          classNames={{ block: style.item }}
          label={t('codeLabel')}
          placeholder={t('codePlaceholder')}
          value={code}
          onChange={setCode}
        />
        <div className={style.buttons}>
          <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
          <Button
            classNames={{ button: style.button }}
            label={t('confirm')}
            isDisabled={isDisabled}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};
