import { fetcher, Method, RESTORE, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

type RestoreBody = {
  email: string;
};

type RestoreResponse = User;

const restore = async (path: string, { arg }: { arg: RestoreBody }) =>
  fetcher<RestoreResponse>({ path, method: Method.POST, body: arg });

export const useRestore = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(RESTORE, restore, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
