import React, { useState } from 'react';
import { Header, VideoRoom, Preparation } from './';
import style from './meeting.module.scss';

export const Meeting = () => {
  const [isActiveMeeting, setIsActiveMeeting] = useState(false);

  const [isActiveMicrophone, setIsActiveMicrophone] = useState(true);
  const [isActiveVideo, setIsActiveVideo] = useState(true);
  const [isHasAccessMicrophone, setIsHasAccessMicrophone] = useState(false);
  const [isHasAccessVideo, setIsHasAccessVideo] = useState(false);
  const [videoDeviceId, setVideoDeviceId] = useState('');
  const [audioDeviceId, setAudioDeviceId] = useState('');
  //TODO: костыль для демо
  //в будущем всю логику с паузой удалить и написать новую
  const [isPause, setPause] = useState(false);

  return (
    <div className={style.container}>
      <Header isActiveMeeting={isActiveMeeting} isPause={isPause} />
      {!isActiveMeeting && (
        <Preparation
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          setVideoDeviceId={setVideoDeviceId}
          setAudioDeviceId={setAudioDeviceId}
          setIsActiveMeeting={setIsActiveMeeting}
          setIsHasAccessMicrophone={setIsHasAccessMicrophone}
          setIsHasAccessVideo={setIsHasAccessVideo}
          setIsActiveMicrophone={setIsActiveMicrophone}
          setIsActiveVideo={setIsActiveVideo}
        />
      )}
      {isActiveMeeting && (
        <VideoRoom
          isActiveMicrophone={isActiveMicrophone}
          isActiveVideo={isActiveVideo}
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          isPause={isPause}
          isHasAccessMicrophone={isHasAccessMicrophone}
          isHasAccessVideo={isHasAccessVideo}
          setPause={setPause}
          setIsActiveMeeting={setIsActiveMeeting}
        />
      )}
    </div>
  );
};
