import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetHeadset } from 'api';
import { PrivateCabinetHeader } from 'components';
import { PRIVATE_CABINET, QUERY } from 'constants/path';
import { Timer, HeadsetSelector } from './';

type HeaderProps = {
  isActiveMeeting: boolean;
  isPause: boolean;
};

export const Header: FC<HeaderProps> = ({ isActiveMeeting, isPause }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data } = useGetHeadset({ id: headsetId });

  const handleBack = () => {
    navigate(PRIVATE_CABINET);
  };

  return (
    <PrivateCabinetHeader title={t('headerTitle')} onBack={isActiveMeeting ? undefined : handleBack}>
      {data && <HeadsetSelector name={data.name} />}
      {isActiveMeeting && <Timer isPause={isPause} />}
    </PrivateCabinetHeader>
  );
};
