import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPause } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useGetMe } from 'api';
import { io, Socket } from 'socket.io-client';
import { useJanus } from 'hooks';
import { OtherContent } from 'pages/meeting/constants';
import { getAuthorizationToken } from 'utils';
import { QUERY } from 'constants/path';
import { ControlsPanel, Content, SnapshotTaken } from './';
import style from './video-room.module.scss';

type VideoRoomProps = {
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  videoDeviceId: string;
  audioDeviceId: string;
  isPause: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VideoRoom: FC<VideoRoomProps> = ({
  isActiveMicrophone,
  isActiveVideo,
  videoDeviceId,
  audioDeviceId,
  isPause,
  isHasAccessMicrophone,
  isHasAccessVideo,
  setPause,
  setIsActiveMeeting,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data: user } = useGetMe();
  const userName = user!.username;

  const [socket, setSocket] = useState<Socket | null>(null);
  const [roomId, setRoomId] = useState(0);
  const [typeOtherContent, setIsTypeOtherContent] = useState<OtherContent | null>(null);
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [isShowVisualEffects, setIsShowVisualEffects] = useState(true);

  const { stream, remoteStreams, pluginHandle } = useJanus({
    userName,
    roomId,
    videoDeviceId,
    audioDeviceId,
    isHasAccessMicrophone,
    isHasAccessVideo,
  });

  useEffect(() => {
    if (!stream) return;

    stream.getVideoTracks().forEach(item => (item.enabled = isActiveVideo));
  }, [stream, isActiveVideo]);

  useEffect(() => {
    if (!stream) return;

    stream.getAudioTracks().forEach(item => (item.enabled = isActiveMicrophone));
  }, [stream, isActiveMicrophone]);

  useEffect(() => {
    const token = getAuthorizationToken();
    const socket = io(`${process.env.REACT_APP_API_WEBSOCKET_URL}`, {
      path: '/ws/user/',
      query: { headset: headsetId },
      transports: ['websocket'],
      auth: {
        Authorization: token,
      },
      withCredentials: true,
    });

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [headsetId]);

  socket?.on('connect', () => {
    console.log('connect');
  });

  socket?.on('error', e => {
    console.error(e);
  });

  socket?.on('room', room_id => {
    setRoomId(room_id);
  });

  return (
    <div className={cn(style.container, { [style.container__pause]: isPause })}>
      {isPause ? (
        //TODO: костыль для демо
        //в будущем всю логику с паузой удалить и написать новую
        <div
          style={{
            position: 'relative',
            height: 'calc(100% - 52px)',
          }}
        >
          <div style={{ margin: '0 auto', width: '220px' }}>
            <FaPause size='220' />
          </div>
          <button
            style={{
              background: 'white',
              border: '1px solid blue',
              borderRadius: '6px',
              width: '320px',
              height: '40px',
              fontSize: '18px',
              color: 'blue',
              cursor: 'pointer',
              position: 'absolute',
              bottom: '20px',
              left: 0,
              right: 0,
              margin: '0 auto',
            }}
            onClick={() => setPause(false)}
          >
            {t('continueConference')}
          </button>
        </div>
      ) : (
        //
        <>
          <Content
            socket={socket}
            userName={userName}
            stream={stream}
            remoteStreams={remoteStreams}
            typeOtherContent={typeOtherContent}
            isFullscreen={isFullscreen}
            headsetId={headsetId}
            isShowVisualEffects={isShowVisualEffects}
          />
          <ControlsPanel
            socket={socket}
            stream={stream}
            pluginHandle={pluginHandle}
            typeOtherContent={typeOtherContent}
            isFullscreen={isFullscreen}
            isHasAccessMicrophone={isHasAccessMicrophone}
            isHasAccessVideo={isHasAccessVideo}
            isActiveMicrophone={isActiveMicrophone}
            isActiveVideo={isActiveVideo}
            isShowVisualEffects={isShowVisualEffects}
            setIsFullScreen={setIsFullScreen}
            setIsShowVisualEffects={setIsShowVisualEffects}
            setIsTypeOtherContent={setIsTypeOtherContent}
            setIsActiveMeeting={setIsActiveMeeting}
            setPause={setPause}
          />
          <SnapshotTaken socket={socket} />
        </>
      )}
    </div>
  );
};
