import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuFlashlight } from 'react-icons/lu';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './controls.module.scss';

type FlashlightProps = {
  socket: Socket;
};

export const Flashlight: FC<FlashlightProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isLight, setIsLight] = useState(false);

  socket.on('light_state', value => {
    setIsLight(value);
  });

  const handleToggle = () => {
    socket?.emit('light_set', !isLight);
    setIsLight(!isLight);
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isLight })}
      title={t('controls.light', { status: isLight ? t('off') : t('on') })}
      onClick={handleToggle}
    >
      <LuFlashlight size='20px' color='black' />
    </button>
  );
};
