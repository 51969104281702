import { fetcher, Method, REGISTRATION, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

type RegistrationUserBody = {
  username: string;
  email: string;
  company_name: string;
  phone: string;
};

type RegistrationUserResponse = User;

const registrationUser = async (path: string, { arg }: { arg: RegistrationUserBody }) =>
  fetcher<RegistrationUserResponse>({ path, method: Method.POST, body: arg });

export const useRegistrationUser = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(REGISTRATION, registrationUser, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
