import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enTranslation } from './en';
import { ruTranslation } from './ru';
import { trTranslation } from './tr';
import { zhTranslation } from './zh';

export const resources = {
  ru: ruTranslation,
  en: enTranslation,
  tr: trTranslation,
  zh: zhTranslation,
} as const;

i18next.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'ru',
  /* debug: true, */
  resources,
});
