import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton, Textarea } from 'components';
import style from './block.module.scss';

const PLEASANT_COMMUNICATION_DATA = [
  {
    nameGroup: 'pleasantCommunication',
    label: '5',
    id: '5',
  },
  {
    nameGroup: 'pleasantCommunication',
    label: '4',
    id: '4',
  },
  {
    nameGroup: 'pleasantCommunication',
    label: '3',
    id: '3',
  },
  {
    nameGroup: 'pleasantCommunication',
    label: '2',
    id: '2',
  },
  {
    nameGroup: 'pleasantCommunication',
    label: '1',
    id: '1',
  },
];

type PleasantCommunicationProps = {
  value: string;
  comment: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
};

export const PleasantCommunication: FC<PleasantCommunicationProps> = ({ value, comment, setValue, setComment }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'finish_meeting' });

  const display = useMemo(
    () =>
      PLEASANT_COMMUNICATION_DATA.map(({ nameGroup, label, id }) => {
        const isChecked = id === value;

        return (
          <RadioButton
            key={id}
            classNames={{ block: style.item }}
            id={id}
            isChecked={isChecked}
            label={label}
            nameGroup={nameGroup}
            onChange={setValue}
          />
        );
      }),
    [value, setValue],
  );

  return (
    <div className={style.block}>
      <div className={style.conteiner}>
        <div>
          <div className={style.title}>{t('pleasant_communication')}</div>
          <div className={style.description}>{t('pleasant_communication_description')}</div>
        </div>
        <div className={style.radioButtonsContainer}>{display}</div>
      </div>
      <Textarea label={t('comment')} placeholder={t('enter_comment')} value={comment} onChange={setComment} />
    </div>
  );
};
