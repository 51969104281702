import { useContext, useEffect, useState } from 'react';
import { IsAuthContext, ActionsIsAuthContext } from 'contexts';
import { getAuthorizationToken } from 'utils';
import { LoadingStatus } from 'constants/constants';

export const useAuth = () => {
  const [authStatus, setAuthStatus] = useState<LoadingStatus>(LoadingStatus.Loading);
  const { isAuth: isAuthContext } = useContext(IsAuthContext);
  const { setIsAuth } = useContext(ActionsIsAuthContext);

  useEffect(() => {
    const token = getAuthorizationToken();
    //TODO: проверять токен на подлиность

    if (token) {
      setAuthStatus(LoadingStatus.Resolve);
      setIsAuth(true);
    } else {
      setAuthStatus(LoadingStatus.Rejact);
      setIsAuth(false);
    }
  }, [isAuthContext, setIsAuth]);

  return authStatus;
};
