export const LAYOUTS_SLICE = {
  'private-cabinet-layout': {
    activateAccount: 'Активировать аккаунт',
    user: 'Пользователь',
    employee: 'сотрудник',
    admin: 'администратор',
    yourBalance: 'Ваш баланс: {{balance}}',
    exit: 'Выйти',
  },
};
