import { fetcher, Method, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

export type UpdateUserPasswordParams = {
  id: string;
};

export type UpdateUserPasswordResponse = User & {
  password: string;
};

const updateUserPassword = async (path: string, { arg: { id } }: { arg: UpdateUserPasswordParams }) =>
  fetcher<UpdateUserPasswordResponse>({ path: `${path}${id}/password/`, method: Method.POST });

export const useUpdateUserPassword = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(USER, updateUserPassword, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
