import { fetcher, Method, HEADSET } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { HeadsetVersion } from 'constants/constants';
import { Headset } from 'types';

export type CreateHeadsetBody = {
  name: string;
  uid: string;
  version: HeadsetVersion;
  key: string;
};

export type CreateHeadsetResponse = Headset;

const createHeadset = async (path: string, { arg }: { arg: CreateHeadsetBody }) =>
  fetcher<CreateHeadsetResponse>({ path, body: arg, method: Method.POST });

export const useCreateHeadset = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(HEADSET, createHeadset, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(HEADSET));
    },
  });
};
