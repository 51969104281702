import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateUserPassword } from 'api';
import { Modal, Button } from 'components';
import { User } from 'types';
import style from './update-user-password-modal.module.scss';

type UpdateUserPasswordModalProps = {
  userData: User;
  onClose: () => void;
};

export const UpdateUserPasswordModal: FC<UpdateUserPasswordModalProps> = ({ userData, onClose }) => {
  const [t] = useTranslation('translation', { keyPrefix: 'modals.update-user-password-modal' });
  const { trigger: updateUserPassword } = useUpdateUserPassword();
  const { id, username } = userData;
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const data = await updateUserPassword({ id });
        const { password } = data;
        setNewPassword(password);
      } catch (e) {
        toast.error(`${(e as { message: string }).message}`);
        console.error(e);
      }
    };

    asyncEffect();
  }, []);

  useEffect(() => {
    if (newPassword) {
      toast.success(t('successMessage'));
    }
  }, [newPassword]);

  return (
    <Modal title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <div className={style.item}>
          <div>{t('login')}</div>
          <div>{username}</div>
        </div>
        <div className={style.item}>
          <div>{t('password')}</div>
          <div>{newPassword}</div>
        </div>
        <Button classNames={{ button: style.button }} label={t('close')} onClick={onClose} />
      </div>
    </Modal>
  );
};
