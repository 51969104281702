import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoChatboxEllipses } from 'react-icons/io5';
import cn from 'classnames';
import { OtherContent } from 'pages/meeting/constants';
import style from './controls.module.scss';

type ChatProps = {
  typeOtherContent: OtherContent | null;
  setIsTypeOtherContent: React.Dispatch<React.SetStateAction<OtherContent | null>>;
};

export const Chat: FC<ChatProps> = ({ typeOtherContent, setIsTypeOtherContent }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleOpenChat = () => {
    setIsTypeOtherContent(state => (state === OtherContent.Chat ? null : OtherContent.Chat));
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: typeOtherContent !== OtherContent.Chat })}
      title={t('controls.chat')}
      onClick={handleToggleOpenChat}
    >
      <IoChatboxEllipses size='25px' color='black' />
    </button>
  );
};
