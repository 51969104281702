export const MEETING_SLICE = {
  headerTitle: 'Конференция гарнитуры:',
  you: 'Вы',
  off: 'Выключить',
  on: 'Включить',
  wrap: 'Свернуть',
  unwrap: 'Развернуть',
  show: 'Показать',
  hidden: 'Скрыть',
  toggleMic: '{{status}} микрофон',
  toggleVideo: '{{status}} видео',
  micNotFound: 'Микрофон не доступен',
  videoNotFound: 'Камера не доступна',
  audioinput: 'Устройство для записи звука',
  videoinput: 'Устройство для записи видео',
  roles: {
    engineer: {
      title: 'В комнате инженеров: {{count}}',
      buttonTitle: 'Войти как инженер',
      description: `Инженер может войти только один инженеру начисляются бонусы описание прав и возможностей инженера`,
    },
    expert: {
      title: 'В комнате экспертов: {{count}}',
      buttonTitle: 'Войти как эксперт',
      description: `Эксперт может войти только один эксперту начисляются бонусы описание прав и возможностей эксперта`,
    },
    observer: {
      title: 'В комнате наблюдетелей: {{count}}',
      buttonTitle: 'Войти как наблюдатель',
      description: `Наблюдателей может войти до трех человекнаблюдатель не получает бонусы описание прав и возможностей наблюдателей`,
    },
    security: {
      title: 'В комнате безопасников: {{count}}',
      buttonTitle: 'Войти как безопасник',
      description: `Безопасник может войти только один безопасник не получает бонусы описание прав и возможностей безопасников`,
    },
  },
  failedBanner:
    'Нет доступа к камере и микрофону,\nподключите их или дайте разрешение на их использование,\nи после обновите страницу',
  continueConference: 'Продолжить конференцию',
  controls: {
    arrow: {
      up: 'Вверх',
      down: 'Вниз',
      left: 'Влево',
      right: 'Вправо',
    },
    chat: 'Чат',
    exit: 'Выйти',
    fix: '{{status}} удержание поля зрения',
    light: '{{status}} фонарик',
    video: '{{status}} видео',
    laser: '{{status}} лазер',
    mic: '{{status}} микрофон',
    record: '{{status}} запись комнаты',
    videoFurther: '{{status}} запрет видео дальше',
    visualEffects: '{{status}} визуальные эффекты',
    people: 'Список людей',
    snapshot: 'Сделать снепшот',
    optionstTitle: 'Настройки',
    options: {
      mouse_sensitivity: 'Чувствительность мыши:',
      keyboard_sensitivity: 'Чувствительность клавиатуры:',
      depth_view: 'Запретить видео дальше:',
    },
  },
};
