import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { Modal } from 'components';
import { Button } from 'components';
import { FINISH_MEETING, QUERY } from 'constants/path';
import style from './exit-video-room.module.scss';

type ExitVideoRoomProps = {
  onClose: () => void;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ExitVideoRoom: FC<ExitVideoRoomProps> = ({ onClose, setPause }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.exit-video-room' });
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const navigate = useNavigate();

  const handleFinish = () => {
    //TODO: логика завершения комнаты
    navigate(`${FINISH_MEETING}?${QUERY.headsetId}=${headsetId}`);
  };

  const handlePutOnPause = () => {
    setPause(true);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <div className={style.content}>
        <Button classNames={{ button: style.button }} label={t('finish')} onClick={handleFinish} />
        <Button classNames={{ button: style.button }} label={t('pause')} onClick={handlePutOnPause} />
        <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('cancel')} onClick={onClose} />
      </div>
    </Modal>
  );
};
