import React, { FC, useRef } from 'react';
import cn from 'classnames';
import style from './radio-button.module.scss';

type RadioButtonProps = {
  nameGroup: string;
  label: string;
  isChecked: boolean;
  id: string;
  classNames?: { block?: string; input?: string; label?: string };
  onChange: (value: string) => void;
};

export const RadioButton: FC<RadioButtonProps> = ({ nameGroup, label, isChecked, id, classNames, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    onChange(value);
  };

  const handleClickBlock = () => {
    inputRef.current?.click();
  };

  return (
    <div className={cn(style.block, classNames?.block)} onClick={handleClickBlock}>
      <input
        className={cn(style.input, classNames?.input)}
        ref={inputRef}
        type='radio'
        id={`${nameGroup}_${id}`}
        name={nameGroup}
        value={id}
        checked={isChecked}
        onChange={handleChange}
      />
      <label className={cn(style.label, classNames?.label)} htmlFor={`${nameGroup}_${id}`}>
        {label}
      </label>
    </div>
  );
};
