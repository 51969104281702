import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMe } from 'api';
import { PrivateCabinetHeader } from 'components';
import { UsersColumn, HeadsetsColumn } from './components';
import style from './private-cabinet.module.scss';

export const PrivateCabinet = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const { data: user } = useGetMe();

  const isAdmin = user && !user.parent;

  return (
    <>
      <PrivateCabinetHeader title={t('title')} />
      <div className={style.container}>
        <div className={style.content}>
          <HeadsetsColumn />
          {isAdmin && <UsersColumn />}
        </div>
      </div>
    </>
  );
};
