import React, { FC } from 'react';
import { IsAuthProvider, WebsocketProvider, WebsocketMessageProvider, IsVisibleHeaderProvider } from './providers';

type ProviderProps = {
  children: React.ReactNode;
};

export const Provider: FC<ProviderProps> = ({ children }) => {
  return (
    <IsAuthProvider>
      <WebsocketProvider>
        <IsVisibleHeaderProvider>
          <WebsocketMessageProvider>{children}</WebsocketMessageProvider>
        </IsVisibleHeaderProvider>
      </WebsocketProvider>
    </IsAuthProvider>
  );
};
