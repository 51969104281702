import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import style from './button.module.scss';

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  label?: string | ReactNode;
  classNames?: { button?: string };
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Button: FC<ButtonProps> = ({ label, classNames, isDisabled, onClick, ...props }) => {
  return (
    <button {...props} className={cn(style.button, classNames?.button)} disabled={isDisabled} onClick={onClick}>
      {label}
    </button>
  );
};
