import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  PrivateCabinet,
  Meeting,
  HeadsetDetails,
  FinishMeeting,
  ConfirmRegistration,
  ConfirmPasswordReset,
} from 'pages';
import { PrivateCabinetLayout } from 'components';
import {
  PRIVATE_CABINET,
  MEETING,
  FINISH_MEETING,
  HEADSET_DETAILS,
  CONFIRM_REGISTRATION,
  CONFIRM_RESET_PASSWORD,
} from 'constants/path';

export const PrivateRouter = () => {
  return (
    <Routes>
      <Route path='*' element={<Navigate to={PRIVATE_CABINET} />} />
      <Route path={PRIVATE_CABINET} element={<PrivateCabinetLayout />}>
        <Route path={PRIVATE_CABINET} element={<PrivateCabinet />} />
        <Route path={`${MEETING}`} element={<Meeting />} />
        <Route path={`${FINISH_MEETING}`} element={<FinishMeeting />} />
        <Route path={`${HEADSET_DETAILS}/:id`} element={<HeadsetDetails />} />
      </Route>
      <Route path={`${CONFIRM_REGISTRATION}/:code`} element={<ConfirmRegistration />} />
      <Route path={`${CONFIRM_RESET_PASSWORD}/:code`} element={<ConfirmPasswordReset />} />
    </Routes>
  );
};
