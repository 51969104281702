import React, { FC, useRef, useEffect, ReactNode } from 'react';
import cn from 'classnames';
import { ResolutionVideoStream, ElementsVideoStream } from './';
import style from './video-stream.module.scss';

type VideoStreamProps = {
  title: string;
  stream: MediaStream | null;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  isShowChildrenIfNotStream?: boolean;
  isShowResolution?: boolean;
  isShowElements?: boolean;
  classNames?: { container?: string; title?: string; video?: string };
  children?: ReactNode;
  onClickVideo?: React.MouseEventHandler<HTMLVideoElement>;
  onClickElements?: React.MouseEventHandler<HTMLDivElement>;
};

export const VideoStream: FC<VideoStreamProps> = ({
  title,
  stream,
  videoRef: videoRefProp,
  isShowChildrenIfNotStream,
  isShowResolution,
  isShowElements,
  classNames,
  children,
  onClickVideo,
  onClickElements,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const blockNotVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current: video } = videoRef;

    if (video) {
      video.srcObject = stream;
    }
  }, [stream]);

  useEffect(() => {
    const { current: video } = videoRef;

    if (videoRefProp) {
      videoRefProp.current = video;
    }
  }, [stream, videoRefProp]);

  const getVideoStyles = () => {
    const style: React.CSSProperties = {};
    const { userAgent } = navigator;

    if (!userAgent.includes('Chrome') && userAgent.includes('Safari')) {
      const { current: videoCurrent } = videoRef;
      const { current: containerCurrent } = videoContainerRef;

      if (videoCurrent && containerCurrent) {
        const { clientHeight: videoHeight } = videoCurrent;
        const { clientHeight: containerHeight } = containerCurrent;

        if (videoHeight > containerHeight) {
          style['height'] = containerHeight;
        }
      }
    }

    return style;
  };

  return (
    <div className={cn(style.container, classNames?.container)}>
      <div className={cn(style.title, classNames?.title)}>{title}</div>
      <div className={style.videoContainter} ref={videoContainerRef}>
        {stream ? (
          <>
            <video
              className={cn(style.video, classNames?.video)}
              style={getVideoStyles()}
              autoPlay
              playsInline
              ref={videoRef}
              onClick={onClickVideo}
            />
            {isShowResolution && <ResolutionVideoStream video={videoRef} />}
            {isShowElements && <ElementsVideoStream video={videoRef} onClick={onClickElements} />}
            {children}
          </>
        ) : (
          <div
            ref={blockNotVideoRef}
            className={cn(style.video, style.video__notStream, classNames?.video)}
            style={{ width: `${(blockNotVideoRef.current?.clientHeight ?? 0) * 1.77}px` }}
          >
            {isShowChildrenIfNotStream && children}
          </div>
        )}
      </div>
    </div>
  );
};
