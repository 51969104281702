import { fetcher, Method, USER } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { User } from 'types';

export type GetUsersParams = {
  limit?: number;
  offset?: number;
};

export type GetUsersResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: User[];
};

const getUsers = async (path: string) => fetcher<GetUsersResponse>({ path, method: Method.GET });

export const useGetUsers = (params: GetUsersParams) =>
  useSWR(`${USER}?limit=${params?.limit}&offset=${params?.offset}`, getUsers);

export const useInfinityGetUsers = (params: Omit<GetUsersParams, 'offset'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const offset = pageIndex * (params.limit ?? 20);

    return `${USER}?limit=${params?.limit}&offset=${offset}`;
  }, getUsers);
