import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineAim } from 'react-icons/ai';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './controls.module.scss';

type LaserProps = {
  socket: Socket;
};

export const Laser: FC<LaserProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isLaser, setIsLaser] = useState(false);

  socket.on('laser_state', value => {
    setIsLaser(value);
  });

  const handleToggle = () => {
    socket?.emit('laser_set', !isLaser);
    setIsLaser(!isLaser);
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isLaser })}
      title={t('controls.laser', { status: isLaser ? t('off') : t('on') })}
      onClick={handleToggle}
    >
      <AiOutlineAim size='20px' color='black' />
    </button>
  );
};
