import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { DropdownSelect } from 'components/ui-kit';
import { LOCAL_STORAGE_ACCEPT_LANGUAGE } from 'constants/local-storage';
import style from './language-selector.module.scss';

const DATA_LIST = [
  {
    id: 'ru',
    label: 'RU',
  },
  { id: 'en', label: 'EN' },
  { id: 'tr', label: 'TR' },
  { id: 'zh', label: 'ZH' },
];

type LanguageSelectorProps = {
  classNames?: { block?: string };
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const LanguageSelector: FC<LanguageSelectorProps> = ({ classNames, onClick }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_ACCEPT_LANGUAGE, i18n.resolvedLanguage ?? 'ru');
  }, [i18n]);

  const handleChange = (value: string | undefined) => {
    i18n.changeLanguage(value);
  };

  return (
    <div className={cn(style.block, classNames?.block)} onClick={onClick}>
      <DropdownSelect dataList={DATA_LIST} value={i18n.resolvedLanguage} onChange={handleChange} />
    </div>
  );
};
