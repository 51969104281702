import { useContext } from 'react';
import { Method, LOGOUT, fetcher } from 'api';
import { ActionsIsAuthContext } from 'contexts';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { removeAuthorizationToken } from 'utils';

export const logout = async (path: string) => fetcher({ path, method: Method.POST });

export const useLogout = () => {
  const { mutate } = useSWRConfig();
  const { setIsAuth } = useContext(ActionsIsAuthContext);

  return useSWRMutation(LOGOUT, logout, {
    onSuccess: () => {
      mutate(key => true);
      removeAuthorizationToken();
      setIsAuth(false);
    },
  });
};
