import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from 'hooks';
import { LoadingStatus } from 'constants/constants';
import { PrivateRouter, PublicRoutes } from './routes';

export const AppRoutes = () => {
  const authStatus = useAuth();

  return (
    <BrowserRouter>
      {authStatus === LoadingStatus.Resolve && <PrivateRouter />}
      {authStatus === LoadingStatus.Rejact && <PublicRoutes />}
    </BrowserRouter>
  );
};
