import { fetcher, Method, SNAPSHOT } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

type GetSnapshotParams = {
  headsetId?: string;
  limit?: number;
  offset?: number;
};

export type GetSnapshotResponse = {
  count: number;
  next: string;
  previous: string;
  results: {
    id: string;
    url: string;
    created_at: string;
  }[];
};

const getSnapshot = async (path: string) => fetcher<GetSnapshotResponse>({ path, method: Method.GET });

export const useGetSnapshot = (params: GetSnapshotParams) =>
  useSWR(`${SNAPSHOT}?headset=${params.headsetId}&limit=${params.limit}&offset=${params.offset}`, getSnapshot);

export const useInfinityGetSnapshot = (params: Omit<GetSnapshotParams, 'offset'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const offset = pageIndex * (params.limit ?? 20);

    return `${SNAPSHOT}?headset=${params.headsetId}&limit=${params.limit}&offset=${offset}`;
  }, getSnapshot);
