import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, Modal, UpdateUserPasswordModal } from 'components';
import { User } from 'types';
import style from './confirm-update-user-password-modal.module.scss';

type ConfirmUpdateUserPasswordModalProps = {
  userData: User;
  onClose: () => void;
};

export const ConfirmUpdateUserPasswordModal: FC<ConfirmUpdateUserPasswordModalProps> = ({ userData, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.confirm-update-user-password-modal' });
  const { username } = userData;
  const [isOpenUpdatePassword, setIsOpenUpdatePassword] = useState(false);

  const handleContinue = () => setIsOpenUpdatePassword(true);

  const handleCloseUpdatePasswordModal = () => {
    setIsOpenUpdatePassword(false);
    onClose();
  };

  return (
    <>
      <Modal onClose={onClose} isHidden={isOpenUpdatePassword}>
        <div className={style.content}>
          <div className={style.description}>{t('title', { username })}</div>
          <div className={style.buttons}>
            <Button classNames={{ button: style.button }} label={t('update')} onClick={handleContinue} />
            <Button
              classNames={{ button: cn(style.button, style.button__red) }}
              label={t('cancel')}
              onClick={onClose}
            />
          </div>
        </div>
      </Modal>
      {isOpenUpdatePassword && <UpdateUserPasswordModal userData={userData} onClose={handleCloseUpdatePasswordModal} />}
    </>
  );
};
