import React, { FC, useState } from 'react';
import { JanusJS } from 'janus-gateway';
import { DropdownSelect } from 'components';
import style from './controls.module.scss';

const DATA_BITRATE = [
  {
    id: 0,
    label: 'No limit',
  },
  {
    id: 128000,
    label: 'Cap to 128kbit',
  },
  {
    id: 256000,
    label: 'Cap to 256kbit',
  },
  {
    id: 512000,
    label: 'Cap to 512kbit',
  },
  {
    id: 1024000,
    label: 'Cap to 1mbit',
  },
  {
    id: 1500000,
    label: 'Cap to 1.5mbit',
  },
  {
    id: 2000000,
    label: 'Cap to 2mbit',
  },
];

type SelectBitrateProps = {
  pluginHandle: JanusJS.PluginHandle;
};

export const SelectBitrate: FC<SelectBitrateProps> = ({ pluginHandle }) => {
  const [bitrate, setBitrate] = useState(DATA_BITRATE[1].id);

  const handleChange = (id: number) => {
    pluginHandle.send({
      message: { request: 'configure', bitrate: id },
    });
    setBitrate(id);
  };

  return (
    <div className={style.selectBitrate}>
      <DropdownSelect isTop dataList={DATA_BITRATE} value={bitrate} onChange={handleChange} />
    </div>
  );
};
