export const ID_ROOT_MODAL_ELEMENT = 'root-modal-element';
export const ID_ROOT_NOTIFICATION_ELEMENT = 'root-notification-element';

export enum UserRole {
  Admin = 'admin',
  Default = 'default',
}

export enum HeadsetVersion {
  v1_0 = 'v1.0',
  v2_0 = 'v2.0',
  v2_1 = 'v2.1',
}

export enum LoadingStatus {
  Loading = 'loading',
  Resolve = 'resolve',
  Rejact = 'rejact',
}
