import React, { FC, useState } from 'react';
import { TIsAuthContext, IsAuthContext, ActionsIsAuthContext } from 'contexts';

type IsAuthProviderProps = {
  children: React.ReactNode;
};

export const IsAuthProvider: FC<IsAuthProviderProps> = ({ children }) => {
  const [isAuth, setIsAuth] = useState<TIsAuthContext['isAuth']>(false);

  return (
    <IsAuthContext.Provider value={{ isAuth }}>
      <ActionsIsAuthContext.Provider value={{ setIsAuth }}>{children}</ActionsIsAuthContext.Provider>
    </IsAuthContext.Provider>
  );
};
