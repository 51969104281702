import { createContext } from 'react';

export type TWebsocketContext = {
  socket: WebSocket | null;
};

export type TActionsWebsocketContext = {
  setSocket: React.Dispatch<React.SetStateAction<TWebsocketContext['socket']>> | (() => null);
};

export const WebsocketContext = createContext<TWebsocketContext>({
  socket: null,
});

export const ActionsWebsocketContext = createContext<TActionsWebsocketContext>({
  setSocket: () => null,
});
