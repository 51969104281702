import { fetcher, SNAPSHOT, Method } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

type UpdateSnapshotParams = {
  id: string;
  formData: FormData;
};

type UpdateSnapshotResponse = {
  created_at: string;
  id: string;
  url: string;
};

const updateSnapshot = async (path: string, { arg: { id, formData } }: { arg: UpdateSnapshotParams }) =>
  fetcher<UpdateSnapshotResponse>({
    path: `${path}${id}/`,
    method: Method.PUT,
    formData,
  });

export const useUpdateSnapshot = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(SNAPSHOT, updateSnapshot, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(SNAPSHOT));
    },
  });
};
