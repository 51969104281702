export enum Status {
  Active = 'active',
  NotActive = 'not-active',
}

export const DATA_TABS_STATUS = [
  {
    id: Status.Active,
    label: 'statuses.active',
  },
  {
    id: Status.NotActive,
    label: 'statuses.notActive',
  },
] as const;

export const CONVERT_STATUS_TO_BOOLEAN: Record<string, boolean> = {
  [Status.Active]: true,
  [Status.NotActive]: false,
};
