import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { ModalPortal } from './modal-portal';
import style from './modal.module.scss';

type ModalProps = {
  children: ReactNode;
  title?: string;
  isHidden?: boolean;
  classNames?: { modal?: string };
  onClose: () => void;
};

export const Modal: FC<ModalProps> = ({ children, title, isHidden, classNames, onClose }) => {
  return (
    <ModalPortal>
      <div className={style.background} onClick={onClose}></div>
      <div className={cn(style.modal, { [style.modal__hidden]: isHidden }, classNames?.modal)}>
        {title && <div className={style.title}>{title}</div>}
        {children}
      </div>
    </ModalPortal>
  );
};
