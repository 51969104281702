import { fetcher, Method, GET_ME } from 'api';
import useSWR from 'swr';
import { User } from 'types';

export type GetMeResponse = User;

const getMe = async (path: string) => fetcher<GetMeResponse>({ path, method: Method.GET });

// !!data.parent ? UserRole.Default : UserRole.Admin;

export const useGetMe = () => useSWR(GET_ME, getMe);
