export const REGISTRATION_SLICE = {
  successMessage: 'Şifre ve onay kodu {{email}} adresine gönderildi',
  title: 'Kayıt',
  loginLabel: 'Giriş',
  loginPlaceholder: 'Giriş yapınız',
  phoneLabel: 'Telefon',
  phonePlaceholder: 'Telefon giriniz',
  emailLabel: 'E-posta',
  emailPlaceholder: 'E-posta giriniz',
  componyNameLabel: 'Şirket Adı',
  componyNamePlaceholder: 'Şirket adı giriniz',
  registrationButton: 'Kaydol',
};
