export const FINISH_MEETING_SLICE = {
  conference_end_headset: 'Завершение конференции гарнитуры: {{name}}',
  task_complite: 'Задание выполнено',
  task_complite_description: '(0% - не выполнено, 100% - выполнено полностью)',
  headset_communication_quality: 'Качество связи по гарнитуре',
  headset_communication_quality_description: '(0 - плохое, 5 - отличное)',
  pleasant_communication: 'Насколько приятно общаться с экспертом',
  pleasant_communication_description: '(0 - не приятно, 5 - очень приятно)',
  quality_expertise: 'Качество экспертизы',
  quality_expertise_description: '(0 - плохо, 5 - отлично)',
  comment: 'Комментарий',
  enter_comment: 'Введите комментарий',
  next: 'Продолжить',
  your_rating: 'Ваш рейтинг: {{rating}}',
  message: 'Поздравляем, вы\nзаработали {{amount}} рублей.\nСпасибо за работу!',
  finish: 'Завершить',
};
