import {
  AUTH_SLICE,
  CONFIRM_PASSWORD_RESET_SLICE,
  CONFIRM_REGISTRATION_SLICE,
  FINISH_MEETING_SLICE,
  HEADSET_DETAILS_SLICE,
  LOGIN_SLICE,
  PASSWORD_RESET,
  PRIVATE_CABINET_SLICE,
  REGISTRATION_SLICE,
  MEETING_SLICE,
  MODALS_SLICE,
  LAYOUTS_SLICE,
} from './slices';

export const trTranslation = {
  translation: {
    auth: AUTH_SLICE,
    confirm_password_reset: CONFIRM_PASSWORD_RESET_SLICE,
    confirm_registration: CONFIRM_REGISTRATION_SLICE,
    finish_meeting: FINISH_MEETING_SLICE,
    headset_details: HEADSET_DETAILS_SLICE,
    login: LOGIN_SLICE,
    password_reset: PASSWORD_RESET,
    private_cabinet: PRIVATE_CABINET_SLICE,
    registration: REGISTRATION_SLICE,
    meeting: MEETING_SLICE,
    modals: MODALS_SLICE,
    layouts: LAYOUTS_SLICE,
  },
};
