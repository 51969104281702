import { useCallback, useEffect } from 'react';
import { Socket } from 'socket.io-client';

const KEY_CODE = {
  w: 'KeyW',
  a: 'KeyA',
  s: 'KeyS',
  d: 'KeyD',
  arrowUp: 'ArrowUp',
  arrowLeft: 'ArrowLeft',
  arrowDown: 'ArrowDown',
  arrowRight: 'ArrowRight',
};

export const useKeyboardEvents = (socket: Socket | null) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!socket) return;

      const { code, repeat } = e;

      if (repeat) return;

      if (code === KEY_CODE.w || code === KEY_CODE.arrowUp) {
        socket.emit('up', true);
      } else if (code === KEY_CODE.a || code === KEY_CODE.arrowLeft) {
        socket.emit('left', true);
      } else if (code === KEY_CODE.s || code === KEY_CODE.arrowDown) {
        socket.emit('down', true);
      } else if (code === KEY_CODE.d || code === KEY_CODE.arrowRight) {
        socket.emit('right', true);
      }
    },
    [socket],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (!socket) return;

      const { code } = e;

      if (code === KEY_CODE.w || code === KEY_CODE.arrowUp) {
        socket.emit('up', false);
      } else if (code === KEY_CODE.a || code === KEY_CODE.arrowLeft) {
        socket.emit('left', false);
      } else if (code === KEY_CODE.s || code === KEY_CODE.arrowDown) {
        socket.emit('down', false);
      } else if (code === KEY_CODE.d || code === KEY_CODE.arrowRight) {
        socket.emit('right', false);
      }
    },
    [socket],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);
};
