import { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ID_ROOT_MODAL_ELEMENT } from 'constants/constants';

type ModalPortalProps = {
  children: ReactNode;
};

export const ModalPortal: FC<ModalPortalProps> = ({ children }) => {
  const [rootModal, setRootModal] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const rootModal = document.getElementById(ID_ROOT_MODAL_ELEMENT);
    setRootModal(rootModal);
  }, []);

  return <>{rootModal && createPortal(children, rootModal)}</>;
};
