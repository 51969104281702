import React, { FC } from 'react';
import cn from 'classnames';
import style from './input-range.module.scss';

type InputRangeProps = {
  id: string;
  label: string;
  min: number;
  max: number;
  value: number;
  step?: number;
  labelValue?: string;
  classNames?: { block?: string; labelContainer?: string; label?: string; input?: string; labelValue?: string };
  onChange: (value: number, e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputRange: FC<InputRangeProps> = ({
  id,
  label,
  min,
  max,
  value,
  step,
  labelValue,
  classNames,
  onChange,
}) => {
  const textValue = labelValue ? `${value} ${labelValue}` : value;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    const numberValue = +value;
    onChange(numberValue, e);
  };

  return (
    <div className={cn(style.block, classNames?.block)}>
      <div className={cn(style.labelContainer, classNames?.labelContainer)}>
        <label className={cn(style.label, classNames?.label)} htmlFor={id}>
          {label}
        </label>
        <div className={cn(style.labelValue, classNames?.labelValue)}>{textValue}</div>
      </div>
      <input
        className={cn(style.input, classNames?.input)}
        type='range'
        id={id}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
