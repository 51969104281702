import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { type JanusJS } from 'janus-gateway';
import { type RemoteStreams } from 'hooks/use-janus';
import { AllStreamsItem } from './all-streams-item';
import style from './streams.module.scss';

type TAllStreams = {
  id: string;
  pluginHandle: JanusJS.PluginHandle | null;
  title: string;
  stream: MediaStream;
}[];

type AllStreamsProps = {
  isVisivle: boolean;
  userName: string;
  myStream: MediaStream | null;
  remoteStreams: RemoteStreams;
  selectStreamId: string;
  setSelectStreamId: React.Dispatch<React.SetStateAction<string>>;
};

export const AllStreams: FC<AllStreamsProps> = ({
  isVisivle,
  userName,
  myStream,
  remoteStreams,
  selectStreamId,
  setSelectStreamId,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const allStreams = useMemo(() => {
    const allStreams: TAllStreams = [];

    if (myStream && userName) {
      const myStreamItem = { id: myStream.id, pluginHandle: null, title: `${t('you')}: ${userName}`, stream: myStream };
      allStreams.push(myStreamItem);
    }

    const remoteStreamsArr = Object.values(remoteStreams);
    remoteStreamsArr.forEach(item => {
      const { stream, display, pluginHandle } = item;
      const id = stream.id;

      allStreams.push({ id, pluginHandle, title: display, stream });
    });

    return allStreams;
  }, [t, userName, myStream, remoteStreams]);

  const display = useMemo(
    () =>
      allStreams?.map(
        ({ id, title, stream, pluginHandle }) =>
          stream.active && (
            <AllStreamsItem
              key={id}
              id={id}
              title={title}
              pluginHandle={pluginHandle}
              stream={stream}
              selectStreamId={selectStreamId}
              setSelectStreamId={setSelectStreamId}
            />
          ),
      ),
    [allStreams, selectStreamId, setSelectStreamId],
  );

  return <div className={cn(style.allStreamsBlock, { [style.allStreamsBlock__hidden]: !isVisivle })}>{display}</div>;
};
