import React, { FC } from 'react';
import cn from 'classnames';
import { type JanusJS } from 'janus-gateway';
import { VideoStream, BitrateVideoStream } from 'components';
import style from './streams.module.scss';

type AllStreamsItemProps = {
  id: string;
  title: string;
  pluginHandle: JanusJS.PluginHandle | null;
  stream: MediaStream;
  selectStreamId: string;
  setSelectStreamId: React.Dispatch<React.SetStateAction<string>>;
};

export const AllStreamsItem: FC<AllStreamsItemProps> = ({
  id,
  title,
  pluginHandle,
  stream,
  selectStreamId,
  setSelectStreamId,
}) => {
  const handleSelectStream = () => {
    setSelectStreamId(id);
  };

  const isSelect = id === selectStreamId;

  return (
    <div className={style.item} onClick={handleSelectStream}>
      <VideoStream
        classNames={{
          container: style.videoContainer,
          video: cn(style.video, { [style.video__active]: isSelect }),
        }}
        title={title}
        stream={stream}
        isShowResolution={!!pluginHandle}
      >
        {pluginHandle && <BitrateVideoStream pluginHandle={pluginHandle} />}
      </VideoStream>
    </div>
  );
};
