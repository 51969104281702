export const LAYOUTS_SLICE = {
  'private-cabinet-layout': {
    activateAccount: '激活账户',
    user: '用户',
    employee: '员工',
    admin: '管理员',
    yourBalance: '您的余额：{{balance}}',
    exit: '退出',
  },
};
