import React, { FC, useEffect, useState } from 'react';
import { type JanusJS } from 'janus-gateway';
import style from './video-stream.module.scss';

const TIME = 1000;

type BitrateVideoStreamProps = {
  pluginHandle: JanusJS.PluginHandle;
};

export const BitrateVideoStream: FC<BitrateVideoStreamProps> = ({ pluginHandle }) => {
  const [bitrate, setBitrate] = useState(pluginHandle.getBitrate());

  useEffect(() => {
    const id = setInterval(() => {
      const bitrate = pluginHandle.getBitrate();
      setBitrate(bitrate);
    }, TIME);

    return () => {
      clearInterval(id);
    };
  }, [pluginHandle]);

  return <div className={style.bitrate}>{bitrate}</div>;
};
