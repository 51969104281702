import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { MdOutlinePermMedia } from 'react-icons/md';
import { RiSlideshow2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useGetMe } from 'api';
import { ChangeHeadsetModal } from 'components';
import { MEETING, HEADSET_DETAILS, QUERY } from 'constants/path';
import { Headset } from 'types';
import style from '../private-cabinet.module.scss';

type UsersColumnItemProps = {
  headsetData: Headset;
  columnItemRef?: React.RefObject<HTMLDivElement>;
};

export const HeadsetsColumnItem: FC<UsersColumnItemProps> = ({ headsetData, columnItemRef }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const navigate = useNavigate();
  const { data: user } = useGetMe();
  const { name, as_user } = headsetData;
  const [isOpenChangeHeadsetModal, setIsOpenChangeHeadsetModal] = useState(false);

  const handleGoToMeeting = () => {
    const { id } = headsetData;
    navigate(`${MEETING}?${QUERY.headsetId}=${id}`);
  };

  const handleGoToHeadsetDetails = () => {
    const { id } = headsetData;
    navigate(`${HEADSET_DETAILS}/${id}`);
  };

  const handleOpenChangeHeadsetModal = () => setIsOpenChangeHeadsetModal(true);
  const handleCloseChangeHeadsetModal = () => setIsOpenChangeHeadsetModal(false);

  const isAdmin = !user?.parent;
  const isUserHeadset = !!as_user;

  return (
    <>
      <div className={style.columnItem} ref={columnItemRef}>
        <div className={style.point}></div>
        <div className={style.name}>
          {name}
          {/* 
          //ошибки никакой нет, но ts почему-то ругается
          //@ts-ignore
          */}
          {isUserHeadset && <span className={style.userHeadset}> {t('userHeadset')}</span>}
        </div>
        <div className={style.buttons}>
          <button className={style.button} title={t('goToMeetingButton')} onClick={handleGoToMeeting}>
            <RiSlideshow2Line size='20px' color='black' />
          </button>
          <button className={style.button} title={t('dataOnHeadsetButton')} onClick={handleGoToHeadsetDetails}>
            <MdOutlinePermMedia size='20px' color='black' />
          </button>
          {isAdmin && (
            <button className={style.button} title={t('editButton')} onClick={handleOpenChangeHeadsetModal}>
              <FiEdit size='20px' color='black' />
            </button>
          )}
        </div>
      </div>
      {isOpenChangeHeadsetModal && (
        <ChangeHeadsetModal headsetData={headsetData} onClose={handleCloseChangeHeadsetModal} />
      )}
    </>
  );
};
