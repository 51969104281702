import React, { FC, ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';
import { NotificationPortal } from './notification-portal';
import style from './notification.module.scss';

type NotificationProps = {
  children: ReactNode;
  onClose: () => void;
};

export const Notification: FC<NotificationProps> = ({ children, onClose }) => {
  return (
    <NotificationPortal>
      <div className={style.notification}>
        <div className={style.block}>
          <button className={style.button}>
            <IoMdClose size='14px' onClick={onClose} />
          </button>
          {children}
        </div>
      </div>
    </NotificationPortal>
  );
};
