//TODO: для теста, потом это будет в снепшоте
import React, { FC, useRef, useEffect, useState } from 'react';
import style from './controls.module.scss';

type TestScreenshotProps = {
  //TODO: принимать стрим с гарнетуры
  stream: MediaStream | null;
};

export const TestScreenshot: FC<TestScreenshotProps> = ({ stream }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  /*  useEffect(() => {
    const { current: video } = videoRef;
    if (!video) return;

    const asyncEffect = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDeviceId,  width: { ideal: 3840 }, height: { ideal: 2160 } },
      });

      video.srcObject = stream;
    };

    asyncEffect();
  }, []); */

  useEffect(() => {
    const { current: video } = videoRef;

    if (video) {
      video.srcObject = stream;
    }
  }, [stream]);

  const handleTakeScreenshot = () => {
    if (!stream) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (!canvas || !video) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    const { width: streamWidth, height: streamHeight } = stream.getVideoTracks()[0].getSettings();
    alert(streamWidth + 'x' + streamHeight);

    if (streamWidth && streamHeight) {
      context.drawImage(video, 0, 0, streamWidth, streamHeight);
      canvas.toBlob(blob => {
        /* setBlob(blob); */
      });
    }
  };

  return (
    <>
      <button
        className={style.button}
        title='Сделать фото, для теста, потом эта логгика будет в кнопке снепшота'
        onClick={handleTakeScreenshot}
      >
        SC
      </button>
      <video ref={videoRef} style={{ display: 'none' }} autoPlay playsInline />
      <canvas
        ref={canvasRef}
        style={{
          display: 'none',
          /* position: 'absolute', top: 100 */
        }}
        width='1000px'
        height='500'
      ></canvas>
      {/* {blob && <img alt='' src={URL.createObjectURL(blob)} />} */}
    </>
  );
};
