import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { IsVisibleHeaderContext } from 'contexts';
//@ts-ignore
import Chatbox from 'https://cdn.jsdelivr.net/npm/@chaindesk/embeds@latest/dist/chatbox/index.js';
//'https://gcore.jsdelivr.net/npm/@chaindesk/embeds@latest/dist/chatbox/index.js';
//'https://cdn.jsdelivr.net/npm/@chaindesk/embeds@latest/dist/chatbox/index.js';
import { Header, CheckListControl } from './';
import style from './private-cabinet-layout.module.scss';

export const PrivateCabinetLayout = () => {
  const { isVisible } = useContext(IsVisibleHeaderContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;

    const chaindeskElement = document.createElement('chaindesk-chatbox-standard');
    document.body.appendChild(chaindeskElement);
    Chatbox.initBubble({
      agentId: 'clz1evn4x00hy2565uop2xkji',
    });

    return () => {
      document.body.removeChild(chaindeskElement);
    };
  }, []);

  return (
    <>
      {/* TODO: добавить лоудер */}
      <div className={style.page}>
        {isVisible && <Header />}
        <div className={cn(style.content, { [style.content__hasHeade]: isVisible })}>
          <Outlet />
        </div>
      </div>
      <CheckListControl />
    </>
  );
};
