import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetHeadset } from 'api';
import { PrivateCabinetHeader } from 'components';
import { PRIVATE_CABINET } from 'constants/path';
import { HeadsetSnapshots, HeadsetVideos } from './components';
import style from './headset-details.module.scss';

export const HeadsetDetails = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'headset_details' });
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetHeadset({ id });

  const handleBack = () => {
    navigate(PRIVATE_CABINET);
  };

  return (
    <>
      <PrivateCabinetHeader title={t('title', { name: data?.name ?? '' })} onBack={handleBack} />
      <div className={style.container}>
        <div className={style.content}>
          <HeadsetSnapshots />
          <HeadsetVideos />
        </div>
      </div>
    </>
  );
};
