import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { RemoteStreams } from 'hooks/use-janus';
import { OtherContent } from 'pages/meeting/constants';
import { Messenger } from 'components';
import { MainStream, AllStreams } from './';
import style from './content.module.scss';

type ContentProps = {
  socket: Socket | null;
  userName: string;
  stream: MediaStream | null;
  remoteStreams: RemoteStreams;
  typeOtherContent: OtherContent | null;
  isFullscreen: boolean;
  headsetId: string | null;
  isShowVisualEffects: boolean;
};

export const Content: FC<ContentProps> = ({
  socket,
  userName,
  stream,
  remoteStreams,
  typeOtherContent,
  isFullscreen,
  headsetId,
  isShowVisualEffects,
}) => {
  const [selectStreamId, setSelectStreamId] = useState('');

  useEffect(() => {
    const myStreamId = stream?.id;

    if (myStreamId) {
      setSelectStreamId(myStreamId);
    }
  }, [headsetId, stream]);

  useEffect(() => {
    if (selectStreamId) return;

    const myStreamId = stream?.id;

    if (myStreamId) {
      setSelectStreamId(myStreamId);
    }
  }, [selectStreamId, stream]);

  return (
    <div className={style.block}>
      <div
        className={cn(style.mainStreamContainer, {
          [style.mainStreamContainer__otherBlock]: typeOtherContent,
          [style.mainStreamContainer__notOtherBlock]: !typeOtherContent,
          [style.mainStreamContainer__fullscreen]: isFullscreen,
        })}
      >
        <MainStream
          socket={socket}
          selectStreamId={selectStreamId}
          userName={userName}
          myStream={stream}
          remoteStreams={remoteStreams}
          isShowVisualEffects={isShowVisualEffects}
        />
      </div>
      <div className={cn(style.otherBlock, { [style.otherBlock__hidden]: !typeOtherContent })}>
        {typeOtherContent === OtherContent.Chat && headsetId && <Messenger chatId={headsetId} />}
        <AllStreams
          isVisivle={typeOtherContent === OtherContent.People}
          userName={userName}
          myStream={stream}
          remoteStreams={remoteStreams}
          selectStreamId={selectStreamId}
          setSelectStreamId={setSelectStreamId}
        />
      </div>
    </div>
  );
};
