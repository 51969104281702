import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfinityGetHeadsets, useGetMe } from 'api';
import { useIntersection } from 'hooks';
import { Column, AddHeadsetModal } from 'components';
import { Headset } from 'types';
import { HeadsetsColumnItem } from './';

const LIMIT = 20;

export const HeadsetsColumn = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const { data: headsetsData, setSize } = useInfinityGetHeadsets({ limit: LIMIT });
  const { data: user } = useGetMe();
  const [headsets, setHeadsets] = useState<Headset[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const columnRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: columnRef.current });

  useEffect(() => {
    if (!isVisible) return;
    setSize(state => ++state);
  }, [isVisible]);

  useEffect(() => {
    if (!headsetsData) return;

    const headsets: Headset[] = [];
    headsetsData.forEach(item => {
      const { results } = item;
      headsets.push(...results);
    });
    setHeadsets(headsets);
  }, [headsetsData]);

  const handleAddHeadset = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const displayList = useMemo(
    () =>
      headsets.map((item, index) => (
        <HeadsetsColumnItem
          key={item.id}
          headsetData={item}
          columnItemRef={index === headsets.length - 1 ? lastItemRef : undefined}
        />
      )),
    [headsets],
  );

  const isAdmin = !user?.parent;

  return (
    <>
      <Column
        title={t('headsetsTitle')}
        buttonLabel={t('addHeadsetButton')}
        columnRef={columnRef}
        isDisabled={!user?.is_verify}
        isHiddenButton={!isAdmin}
        onClick={handleAddHeadset}
      >
        {displayList}
      </Column>
      {isOpenModal && <AddHeadsetModal onClose={handleCloseModal} />}
    </>
  );
};
