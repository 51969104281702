import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RiContractLeftRightLine } from 'react-icons/ri';
import cn from 'classnames';
import style from './controls.module.scss';

type VisualEffectsProps = {
  isShowVisualEffects: boolean;
  setIsShowVisualEffects: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VisualEffects: FC<VisualEffectsProps> = ({ isShowVisualEffects, setIsShowVisualEffects }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleShowVisualEffects = () => {
    setIsShowVisualEffects(state => !state);
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isShowVisualEffects })}
      title={t('controls.visualEffects', { status: isShowVisualEffects ? t('hidden') : t('show') })}
      onClick={handleToggleShowVisualEffects}
    >
      <RiContractLeftRightLine size='25px' color='black' />
    </button>
  );
};
