import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRestore } from 'api';
import { AuthHeader, Input, Button } from 'components';
import { AUTH, LOGIN } from 'constants/path';
import style from './password-reset.module.scss';

export const PasswordReset = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'password_reset' });
  const navigate = useNavigate();
  const { trigger: restore } = useRestore();
  const [email, setEmail] = useState('');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    try {
      await restore({ email });
      toast.success(t('successMessage', { email }));
      navigate(AUTH);
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handleBack = () => {
    navigate(LOGIN);
  };

  const isDisabled = !email;

  return (
    <>
      <AuthHeader title={t('title')} onBack={handleBack} />
      <form onSubmit={handleSubmit}>
        <Input
          classNames={{ block: style.input }}
          type='email'
          label={t('emailLabel')}
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={setEmail}
        />
        <Button classNames={{ button: style.button }} isDisabled={isDisabled} label={t('sendButton')} />
      </form>
    </>
  );
};
